import { shuffleArray } from "./utils";

export const PROPERTIES_ENUM = {
  IS_SUPPORT_ISRAEL: "IS_SUPPORT_ISRAEL",
  HOW: "HOW",
  ALTERNATIVES: "ALTERNATIVES"
};

const PARENT_COMPANIES_NAMES_MAP = {
  cocaCola: "Coca Cola",
  starbucks: "Starbucks",
  mcdonalds: "McDonald's",
  burgerKing: "Burger King",
  dominos: "Domino's",
  yumBrandsInc: "Yum Brands Inc.",
  nestle: "Nestle",
  marsInc: "Mars Inc.",
  volvo: "Volvo",
  cat: "CAT",
  siemens: "Siemens",
  puma: "Puma",
  carrefour: "Carrefour",
  chevron: "Chevron",
  hyundai: "Hyundai",
  osem: "Osem",
  pepsico: "PepsiCo",
  caf: "CAF",
  unilever: "Unilever",
  mondelezInternationalHoldings: "Mondelez International Holdings",
  loreal: "L'Oréal",
  motorola: "Motorola",
  pg: "P&G",
  danone: "Danone",
  henkel: "Henkel",
  hp: "HP",
  reckitt: "Reckitt",
  tatGida: "Tat Gıda",
  bim: "BİM",
  lcWaikiki: "LC Waikiki",
  kigiliGiyim: "Kiğılı Giyim",
  hatemogluTekstil: "Hatemoğlu Tekstil",
  jakamenTekstil: "Jakamen Tekstil",
  orkaHolding: "Orka Holding",
  kotonMagazacilikTekstil: "Koton Mağazacılık Tekstil",
  suvariGiyim: "Süvari Giyim",
  imzaMagazacilik: "İmza Mağazacılık",
  zeyrekTekstil: "Zeyrek Tekstil",
  erogluHolding: "Eroğlu Holding",
  gezerAyakkabi: "Gezer Ayakkabı",
  kingPauloTekstil: "King Paulo Tekstil",
  forelliTekstil: "Forelli Tekstil",
  ydsAyakkabi: "YDS Ayakkabı",
  afiaGida: "Afia Gıda",
  solenCikolataGida: "Şölen Çikolata Gıda",
  erisGida: "Eriş Gıda",
  vipGida: "Vip Gıda",
  yildizHolding: "Yıldız Holding",
  ulker: "Ülker",
  danaDisTicaret: "Dana Dış Ticaret",
  kervanGida: "Kervan Gıda",
  burcuGida: "Burcu Gıda",
  selvaGida: "Selva Gıda",
  aytacGida: "Aytaç Gıda",
  kevserGida: "Kevser Gıda",
  tarisZeytin: "Tariş Zeytin",
  zadeVitalGida: "Zade Vital Gıda",
  orucogluYag: "Oruçoğlu Yağ",
  uludag: "Uludağ",
  hamidiyeSu: "Hamidiye Su",
  kayisdagiSu: "Kayışdağı Su",
  aytacSu: "Aytaç su",
  nigdeGazozu: "Niğde Gazozu",
  beypazariMadenSuyu: "Beypazarı Maden Suyu",
  kizilay: "Kızılay",
  doganay: "Doğanay",
  nazo: "Nazo",
  aroma: "Aroma",
  meyoz: "Meyöz",
  hunkarGazoz: "Hünkar Gazoz",
  evyap: "Evyap",
  naturalive: "Naturalive",
  duru: "Duru",
  birlesikHolding: "Birleşik Holding",
  bingo: "Bingo",
  mintax: "Mintax",
  abc: "ABC",
  peros: "Peros",
  ersag: "Ersağ",
  viking: "Viking",
  flaminfo: "Flaminfo",
  mio: "Mio",
  hes: "Hes",
  paksoy: "Paksoy",
  etiMatik: "Eti Matik",
  telekom: "Telekom",
  vestel: "Vestel",
  casper: "Casper",
  bip: "Bip",
  bimeks: "Bimeks",
  vatanBilgisayar: "Vatan Bilgisayar",
  kaanTelefon: "Kaan Telefon",
  arcelik: "Arçelik",
  beko: "Beko",
  arzum: "Arzum",
  king: "King",
  sinbo: "Sinbo",
  goldMaster: "Gold Master",
  tukas: "Tukaş",
  sutas: "Sütaş",
  yaylaAgroGida: "Yayla Agro Gida",
  erPilicEntegre: "Er Piliç Entegre",
  kervansaray: "Kervansaray",
  tadella: "Tadella",
  merterHelva: "Merter Helva",
  caykur: "Çaykur",
  sagraGrup: "Sagra Grup",
  altinbasHolding: "Altınbaş Holding",
  nuhunAnkaraMakarnasi: "Nuh’un Ankara Makarnası",
  akdoGida: "Akdo Gıda",
  erislerGida: "Erişler Gıda",
  kelloggs: "Kelloggs",
  ferrero: "Ferrero",
  gloriaJeans: "Gloria Jeans",
  nevcoHolding: "Nevco Holding",
  zara: "Zara",
  adidas: "Adidas",
  nike: "Nike",
  seli: "Seli Kaynak ve Meyve Suları",
  apple: "Apple",
  dismes: "Dimes Gıda",
  lilaGroup: "Lila Group",
  oneK: "1K Kimya A.Ş.",
  littleCaesars: "Little Caesar",
  pizzaBulls: "Pizza bulls",
  popeyes: "Popeyes",
  puntoFa: "Punto Fa",
  ozonGiyim: "Ozon Giyim",
  turgutAydinHolding: "Turgut Aydın Holding",
  boyner: "Boyner Büyük Mağazacılık",
  trendyol: "DSM Grup",
  dewalt: "Dewalt",
  mahindra: "mahindra",
  coty: "Coty",
  ebebek: "Ebebek Mağazacılık",
  ozturkGroup: "Öztürk Group",
  passaport: "Pasaport pizza",
  kahveDunyasi: "Kahve Dünyası",
  sleepy: "Eruslu Sağlık Ürünleri",
  kopasKozmetik: "Kopaş Kozmetik",
  kirecburnu: "Kireçburnu Fırnı",
  onlem: "Pakten Sağlık Ürünleri",
  puretex: "Puretex Plastik ve Tekstil Ürünleri",
  deha: "Deha Mağazacılık Ev Tekstil Ürünleri",
  tamek: "Tamek Grup Gıda",
  jAndJ: "Johnson & Johnson",
  generalMotors: "General Motors",
  disney: "Disney",
  eczacibasiTuketim  : "Eczacıbaşı Tüketim",
  marksAndSpencer: "Marks and Spencer",
  drOetker: "Dr. Oetker",
  GSK: 'GlaxoSmithKline plc',
  almarai: 'Almarai',
  anadoluGrubu: 'Anadolu Grubu',
  turkcellIletisimHizmetleri: 'Turkcell İletişim Hizmetleri',
  turkTelekomunikasyon: 'Türk Telekomünikasyon',
  mavi: 'Mavi',
  dogusFoodandBeverageProduction: 'Doğuş Food and Beverage Production',
  a101: 'A101 ',
  dogasal: 'Doğasal ',
  betaGida: 'Beta Gıda ',
  ozsoyUnluMamulleri: 'Özsoy Unlu Mamulleri ',
  tamekGrupGida : 'Tamek Grup Gıda  ',
  scaansYildizHolding : 'SCA ans Yıldız Holding  ',
  arifoglu : 'arifoğlu ',
  turkiyeCumhuriyetMerkezBankasi : 'Türkiye Cumhuriyet Merkez Bankası ',
  sedes : 'SEDES ',
  karakanAilesi  : 'Karakan Ailesi  ',
  malatyaPazariPalanci : 'Malatya Pazarı Palancı ',
};

export const PARENT_COMPANIES_DATA_MAP = {
  [PARENT_COMPANIES_NAMES_MAP.GSK]: {
    isBoycott: false,
    isNeutral: true,
    reason: {
      en: `GSK has an office in Israel, but it has taken a neutral position regarding the war on Gaza, so we leave it to you to choose whether to boycott it or not`,
      tr: `GSK İsrail'de ofisi var ama Gazze'deki savaş konusunda tarafsız bir pozisyon aldı, bu yüzden boykot edip etmeme seçimini size bırakıyoruz.`,
      ar: `GSK لديها مكتب في اسرائيل ولكنها اتخذت موقف الحياد بخصوص الحرب على غزه لذلك نترك لكم اختيار مقاطعتها أم لا `,
    },
    infoLink: "https://www.gsk.com/en-gb/media/our-response-to-the-crisis-in-israel-and-gaza/",
  },
  [PARENT_COMPANIES_NAMES_MAP.marksAndSpencer]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: `Marks and Spencer has championed the state of Israel and thus connived in the dispossession and suppression of the Palestinians.`,
      tr: `Marks and Spencer İsrail devletini savundu ve böylece Filistinlilerin mülksüzleştirilmesine ve bastırılmasına göz yumdu.`,
      ar: `لقد دافعت ماركس آند سبنسر عن دولة إسرائيل، وبالتالي تواطأ في تجريد الفلسطينيين من ممتلكاتهم وقمعهم.`,
    },
    infoLink: "https://www.revolutionarycommunist.org/campaigns/palestine-campaign/1353-marks-and-spencer-ally-of-israel",
  },
  [PARENT_COMPANIES_NAMES_MAP.generalMotors]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: `General Motors has acquired substantially all the assets of Israel-based battery software startup ALGOLiON for an undisclosed sum.`,
      tr: `General Motors, İsrail merkezli pil yazılımı girişimi ALGOLiON'un neredeyse tüm varlıklarını açıklanmayan bir meblağ karşılığında satın aldı.`,
      ar: `استحوذت شركة جنرال موتورز على جميع أصول شركة ALGOLiON الناشئة لبرامج البطاريات ومقرها إسرائيل مقابل مبلغ لم يكشف عنه.`,
    },
    infoLink: "https://web.archive.org/web/20221025202604/https://www.jnj.com/media-center/press-releases/johnson-johnson-innovation-expands-global-incubator-presence-to-israel-and-announces-multiple-diverse-early-stage-collaborations-with-academia-and-biotech",
  },
  [PARENT_COMPANIES_NAMES_MAP.jAndJ]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: `J&J has four subsidiary companies in the Zionist entity. In 1998, Israeli Prime Minister Benjamin Netanyahu awarded the company the Silver Jubilee Award, the highest decoration given by the Zionist entity in recognition of those who "made the utmost effort, through investments and commercial relations, to strengthen the Israeli economy"over fifty years since the establishment of the entity. In 2000, the company invested $2.5 million in the Israeli company Neuro Survival Technologies, specializing in the development of medical tools used in the discovery and modification of "cellular self-destruction.`,
      tr: `Şirketin Siyonist varlıkta dört alt şirketi bulunmaktadır. 1998 yılında, İsrail Başbakanı Benjamin Netanyahu, şirkete "İsrail ekonomisini güçlendirmeye yönelik 50 yıl boyunca yaptığı yatırımlar ve ticari ilişkilerle en üst düzeyde çaba sarf edenlere verilen" en yüksek ödül olan Gümüş Jübile Ödülü'nü verdi. 2000 yılında, şirket, İsrail merkezli Neuro Survival Technologies şirketine 2.5 milyon dolar yatırım yaptı. Bu şirket, hücresel otoimmün yıkımı keşfetme ve düzeltme konusunda uzmanlaşmıştır.`,
      ar: `تمتلك أربع شركات فرعية في الكيان الصهيوني. في عام 1998، منحها رئيس الوزراء الإسرائيلي بنيامين نتنياهو جائزة اليوبيل الفضي، وهي أعلى وسام يمنحه الكيان الصهيوني تقديراً للجهود المبذولة في تقوية الاقتصاد الإسرائيلي على مدى خمسين عامًا. في عام 2000، استثمرت 2.5 مليون دولار في شركة Neuro Survival Technologies الإسرائيلية، المتخصصة في تطوير الأدوات الطبية لاكتشاف وتعديل التدمير الذاتي الخلوي.`,
    },
    infoLink: "https://web.archive.org/web/20221025202604/https://www.jnj.com/media-center/press-releases/johnson-johnson-innovation-expands-global-incubator-presence-to-israel-and-announces-multiple-diverse-early-stage-collaborations-with-academia-and-biotech",
  },
  [PARENT_COMPANIES_NAMES_MAP.mahindra]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Mahindra is a partner of an Israeli defense industry company Aeronautics",
      tr: "Mahindra, İsrailli bir savunma sanayi şirketi Aeronautics'in ortağıdır",
      ar: "ماهيندرا شريك في شركة صناعة الدفاع الإسرائيلية Aeronautics.",
    },
    infoLink: "https://www.calcalistech.com/ctechnews/article/q0enkawas",
  },
  [PARENT_COMPANIES_NAMES_MAP.dewalt]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "The owner of Dewalt company explicitly declared his support for Israel",
      tr: "Dewalt şirketinin sahibi İsrail'e olan desteğini açık bir şekilde açıkladı.",
      ar: "أعلن مالك شركة ديوالت صراحةً دعمه لإسرائيل.",
    },
    infoLink: "https://www.calcalistech.com/ctechnews/article/q0enkawas",
  },
  [PARENT_COMPANIES_NAMES_MAP.adidas]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Adidas has many branches in Israel. These branches contribute to Israel’s economy, provides job opportunities for Israelis, and thus supports the continued existence of Israel.",
      tr: "Adidas'ın İsrail'de faaliyet gösteren birçok şubesi bulunmaktadır. Bu şubeler, İsrail ekonomisine katkı sağlamakta, İsraillilere iş imkânları sunmakta ve dolayısıyla İsrail işgalci devletinin varlığını desteklemektedir.",
      ar: "تمتلك أديداس عدة فروع في إسرائيل، تساهم هذه الفروع في اقتصاد إسرائيل، وتوفّر فرص عمل للإسرائيليين، مما يدعم بالتالي استمرار وجود دولة الاحتلال.",
    },
    infoLink: "https://bdsmovement.net/adidas",
  },
  [PARENT_COMPANIES_NAMES_MAP.nike]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Adidas has many branches in Israel. These branches contribute to Israel’s economy, provides job opportunities for Israelis, and thus supports the continued existence of Israel.",
      tr: "Adidas'ın İsrail'de faaliyet gösteren birçok şubesi bulunmaktadır. Bu şubeler, İsrail ekonomisine katkı sağlamakta, İsraillilere iş imkânları sunmakta ve dolayısıyla İsrail işgalci devletinin varlığını desteklemektedir.",
      ar: "تمتلك نايكي عدة فروع في إسرائيل، تساهم هذه الفروع في اقتصاد إسرائيل، وتوفّر فرص عمل للإسرائيليين، مما يدعم بالتالي استمرار وجود دولة الاحتلال.",
    },
    infoLink: "https://www.nike.com/tr/retail/directory/i%cc%87srail/israel",
  },
  [PARENT_COMPANIES_NAMES_MAP.zara]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "The Zara franchise owner in Israel Joey Schwebel from the country hosted far-right politician Itamar Ben-Gvir for a campaign event.",
      tr: "İsrail'deki Zara imtiyaz sahibi Joey Schwebel, bir kampanya etkinliği için aşırı sağcı politikacı Itamar Ben-Gvir'i ağırladı.",
      ar: "استضاف صاحب امتياز زارا في إسرائيل جوي شويبل من البلاد السياسي اليميني المتطرف إيتامار بن جفير في حدث انتخابي.",
    },
    infoLink: "https://www.euronews.com/2022/10/24/israel-election-zara",
  },
  [PARENT_COMPANIES_NAMES_MAP.zara]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "The Zara franchise owner in Israel Joey Schwebel from the country hosted far-right politician Itamar Ben-Gvir for a campaign event.",
      tr: "İsrail'deki Zara imtiyaz sahibi Joey Schwebel, bir kampanya etkinliği için aşırı sağcı politikacı Itamar Ben-Gvir'i ağırladı.",
      ar: "استضاف صاحب امتياز زارا في إسرائيل جوي شويبل من البلاد السياسي اليميني المتطرف إيتامار بن جفير في حدث انتخابي.",
    },
    infoLink: "https://www.euronews.com/2022/10/24/israel-election-zara",
  },
  [PARENT_COMPANIES_NAMES_MAP.cocaCola]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Coca-Cola is violating international law by operating on the occupied land of Palestine.",
      tr: "Coca-Cola işgal altında kalan Filistin topraklarında faaliyet göstererek uluslararası hukuku ihlal ediyor.",
      ar: "إن شركة كوكا كولا تنتهك القانون الدولي من خلال عملها على الأراضي الفلسطينية المحتلة.",
    },
    infoLink: "https://foa.org.uk/campaign/boycottcocacola",
  },
  [PARENT_COMPANIES_NAMES_MAP.starbucks]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Starbucks filed a lawsuit against its labor union over a message of support for Palestinians posted on the union's social media account.",
      tr: "Starbucks, kendi sendikanın sosyal medya hesabında yayınlanan Filistinlilere destek mesajı nedeniyle sendikaya dava açtı.",
      ar: "رفعت ستاربكس دعوى قضائية ضد نقابتها العمالية بسبب رسالة دعم للفلسطينيين نُشرت على حساب النقابة على وسائل التواصل الاجتماعي.",

    },
    infoLink: "https://www.pbs.org/newshour/economy/starbucks-workers-united-union-sue-each-other-in-standoff-over-pro-palestinian-social-media-post",
  },
  [PARENT_COMPANIES_NAMES_MAP.mcdonalds]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "McDonald's, like other global fast food chains such as Domino's Pizza and Burger King, offered free meals to the Israeli army.",
      tr: "McDonald's, Domino's Pizza ve Burger King gibi diğer küresel fast food zincirleri gibi İsrail ordusuna bedava yemek teklif etti.",
      ar: "قدمت ماكدونالدز، مثل غيرها من سلاسل الوجبات السريعة العالمية مثل دومينوز بيتزا وبرجر كنج، وجبات مجانية للجيش الإسرائيلي.",
    },
    infoLink: "https://www.vox.com/world-politics/23935054/boycott-movement-palestine-against-israel-bds",
  },
  [PARENT_COMPANIES_NAMES_MAP.burgerKing]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Burger King, like other global fast food chains such as Domino's Pizza and McDonald's, offered free meals to the Israeli army.",
      tr: "Burger King, Domino's Pizza ve McDonald’s gibi diğer küresel fast food zincirleri gibi İsrail ordusuna bedava yemek teklif etti.",
      ar: "قدمت شركة برجر كنج، مثل غيرها من سلاسل الوجبات السريعة العالمية مثل دومينوز بيتزا وماكدونالدز، وجبات مجانية للجيش الإسرائيلي.",
    },
    infoLink: "https://www.vox.com/world-politics/23935054/boycott-movement-palestine-against-israel-bds",
  },
  [PARENT_COMPANIES_NAMES_MAP.popeyes]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Popeyes is the sister company of Burger King which offered free meals to the Israeli army.",
      tr: "Popeyes, İsrail ordusuna bedava yemek sunan Burger King'in kardeş şirketidir.",
      ar: "Popeyes هي الشركة الشقيقة لبرجر كينج التي تقدم وجبات مجانية للجيش الإسرائيلي.",
    },
    infoLink: "https://www.rbi.com/English/brands/default.aspx",
  },
  [PARENT_COMPANIES_NAMES_MAP.dominos]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Domino's Pizza, like other global fast food chains such as Burger King and McDonald's, offered free meals to the Israeli army.",
      tr: "Domino's Pizza, McDonald’s ve Burger King gibi diğer küresel fast food zincirleri gibi İsrail ordusuna bedava yemek teklif etti.",
      ar: "قدمت دومينوس بيتزا ، مثل سلاسل الوجبات السريعة العالمية الأخرى مثل برجر كينج وماكدونالدز ، وجبات مجانية للجيش الإسرائيلي.",
    },
    infoLink: "https://www.vox.com/world-politics/23935054/boycott-movement-palestine-against-israel-bds",
  },
  [PARENT_COMPANIES_NAMES_MAP.yumBrandsInc]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Yum! Brands owns KFC and  Pizza hut. which has provided israeli soldiers with food while they are fighting after the situation on 7th of October.",
      tr: "Yum! Brands KFC ve Pizza Hut'a sahiptir. 7 Ekim'deki durumdan sonra savaşırken İsrail askerlerine ücretsiz yiyecek dağıttı.",
      ar: "Yum! Brands تمتلك KFC و Pizza Hut. التي زودت الجنود الإسرائيليين بالطعام أثناء قتالهم بعد الحرب في 7 أكتوبر.",
    },
    infoLink: "https://www.vox.com/world-politics/23935054/boycott-movement-palestine-against-israel-bds",
  },
  [PARENT_COMPANIES_NAMES_MAP.nestle]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      tr: "Nestle'nin İsrail'deki ana varlığı, 1951 yılında Gazze Şeridi'ne bir kilometre uzaklıkta kurulan ve 1948'de etnik temizliğe tabi tutulan Filistin kasabası Al-Najd'ın toprakları üzerine inşa edilen bir yerleşim yeri olan Sderot'ta bulunuyor. Nestle, İsrail ekonomisinin gelişebilmesi için işgal altındaki Filistin toprakları üzerine kuruluyor ve böyle bir ırk ayrımı sisteminin ürünlerini yurt dışına satıyor.",
      en: "Nestle's main presence in Israel is in Sderot, a settlement founded in 1951 one kilometer from the Gaza Strip which is built on the lands of the Palestinian town Al-Najd, which was ethnically cleansed in 1948. Nestle builds on occupied Palestinian lands and sells the products of such an apartheid system abroad so that the Israeli economy can flourish.",
      ar: "يقع التواجد الرئيسي لشركة نستله في إسرائيل في سديروت، وهي مستوطنة تأسست عام 1951 على بعد كيلومتر واحد من قطاع غزة، وتم بناؤها على أراضي بلدة النجد الفلسطينية، التي تم تطهيرها عرقياً عام 1948. وتبني نستله على الأراضي الفلسطينية المسروقة وتبيع الأراضي الفلسطينية. منتجات نظام الفصل العنصري هذا في الخارج حتى يتمكن الاقتصاد الإسرائيلي من الازدهار.",
    },
    infoLink: "https://www.reuters.com/article/us-osem-investments-m-a-nestle-idUSKCN0VD0Y5",
  },
  [PARENT_COMPANIES_NAMES_MAP.marsInc]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en:"Mars, Incorporated has partnered with Jerusalem Venture Partners (JVP) to foster innovative food tech solutions in Israel. This involves supporting existing Israeli startups, facilitating the creation of new ones, and collaborating with top Israeli academic institutions, including the Hebrew University, the Weizmann Institute, and the Technion, to drive further advancements.",
      tr: "Mars, İsrail'de yenilikçi gıda teknolojisi çözümlerini teşvik etmek için Jerusalem Venture Partners (JVP) ile ortaklık kurdu. Bu, mevcut İsrailli girişimlerin desteklenmesini, yenilerinin yaratılmasını kolaylaştırmayı ve daha fazla ilerleme sağlamak için İbrani Üniversitesi, Weizmann Enstitüsü ve Technion dahil olmak üzere İsrail'in önde gelen akademik kurumlarıyla işbirliği yapmayı içerir.",
      ar:"أبرمت شركة مارس إنكوربوريتد شراكة مع شركة القدس فينشر بارتنرز لتعزيز حلول تكنولوجيا الأغذية المبتكرة في إسرائيل. ويتضمن ذلك دعم الشركات الناشئة الإسرائيلية القائمة، وتسهيل إنشاء شركات جديدة، والتعاون مع أفضل المؤسسات الأكاديمية الإسرائيلية، بما في ذلك الجامعة العبرية، ومعهد وايزمان، والتخنيون.",
    },
    infoLink: "https://nocamels.com/2019/05/snickers-mars-deal-jvp-israeli-food-tech",
  },
  [PARENT_COMPANIES_NAMES_MAP.volvo]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en:"Bulldozers from companies such as Volvo, Hyundai Heavy Industries, JCB and Caterpillar are an integral part of Israel’s illegal destruction of Palestinian homes and livelihoods. In repeated instances, we have witnessed Israeli occupation forces utilizing these machines to flatten out Palestinian homes, sometimes entire buildings.",
      tr: "Volvo, Hyundai Ağır endüstriler, JCB ve Caterpillar gibi şirketlerin buldozerleri, İsrail'in Filistinlilerin evlerini ve geçim kaynaklarını yasadışı bir şekilde yok etmesinin ayrılmaz bir parçası. İsrail işgal güçlerinin bu makineleri Filistinlilerin evlerini, bazen de tüm binalarını yerle bir etmek için kullandığına defalarca tanık olduk.",
      ar:"تشكل الجرافات التابعة لشركات مثل فولفو وهيونداي للصناعات الثقيلة وجي سي بي وكاتربيلر جزءًا لا يتجزأ من التدمير غير القانوني الذي تقوم به إسرائيل لمنازل الفلسطينيين وسبل عيشهم. وفي حالات متكررة، شهدنا قوات الاحتلال الإسرائيلي تستخدم هذه الآلات لتسوية منازل الفلسطينيين، وفي بعض الأحيان مباني بأكملها."
    },
    infoLink: "https://www.facebook.com/BDSNationalCommittee/posts/volvo-group-continues-its-complicity-in-israels-daily-attacks-on-palestinians-in/2294456830599164/",
  },
  [PARENT_COMPANIES_NAMES_MAP.cat]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en:"Caterpillar Inc. supplies the militarized D9 bulldozers used by Israel in the Occupied Palestinian Territories to demolish thousands of Palestinian homes, making way for Jewish-only settlements.",
      tr: "Caterpillar Inc., İsrail'in İşgal Altındaki Filistin Toprakları'nda binlerce Filistinlinin evini yıkmak ve yalnızca Yahudilerin yaşadığı yerleşimlere yer açmak için kullandığı militarize D9 buldozerlerini sağlıyor.",
      ar: "تقوم شركة كاتربيلر بتزويد الجرافات العسكرية التي تستخدمها إسرائيل في الأراضي الفلسطينية المحتلة بهدم آلاف المنازل الفلسطينية، مما يفسح المجال أمام إقامة المستوطنات اليهودية فقط."
    },
    infoLink: "https://bdsmovement.net/news/after-gaza-massacre-40-cambridge-university-student-groups-demand-boycott-bae-and-caterpillar",
  },
  [PARENT_COMPANIES_NAMES_MAP.siemens]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Siemens, as demonstrated by a study of the Palestinian Human Rights Organizations Council (PHROC), is complicit in Israel’s illegal settlement enterprise in the occupied Palestinian territory, including East Jerusalem, through its construction of the EuroAsia Interconnector. These settlements are internationally condemned as a war crime under international humanitarian law.",
      tr: "Filistin İnsan Hakları Örgütleri Konseyi'nin (PHROC) yaptığı bir çalışmanın da ortaya koyduğu gibi Siemens, Avrasya Interconnector'u inşa ederek İsrail'in Doğu Kudüs de dahil olmak üzere işgal altındaki Filistin topraklarındaki yasadışı yerleşim girişiminin suç ortağıdır. Bu yerleşimler, uluslararası insancıl hukuk uyarınca savaş suçu olarak uluslararası alanda kınanıyor.",
      ar: "إن شركة سيمنز، كما أظهرت دراسة أجراها مجلس منظمات حقوق الإنسان الفلسطينية، متواطئة في المشروع الاستيطاني الإسرائيلي غير القانوني في الأرض الفلسطينية المحتلة، بما في ذلك القدس الشرقية، من خلال بناء خط الربط الكهربائي الأوروبي الآسيوي. وهذه المستوطنات تحظى بإدانة دولية باعتبارها جريمة حرب بموجب القانون الإنساني الدولي.",
    },
    infoLink: "https://bdsmovement.net/siemens-and-chevron-stop-fueling-apartheid-and-climate-disaster",
  },
  [PARENT_COMPANIES_NAMES_MAP.puma]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "PUMA sponsors the Israel Football Association (IFA) and the Israeli national team. The IFA manages several football teams in illegal Israeli settlements, built on occupied Palestinian land.",
      tr: "PUMA, İsrail Futbol Federasyonu'na (IFA) ve İsrail milli takımına sponsorluk yapmaktadır. IFA, işgal altındaki Filistin topraklarında inşa edilen yasa dışı İsrail yerleşimlerinde birçok futbol takımını yönetiyor.",
      ar: "\"ترعى شركة بوما الاتحاد الإسرائيلي لكرة القدم والمنتخب الإسرائيلي. ويدير الاتحاد الإسرائيلي لكرة القدم العديد من فرق كرة القدم في المستوطنات الإسرائيلية غير القانونية، فوق الأراضي الفلسطينية المحتلة.\"",
    },
    infoLink: "https://www.foa.org.uk/campaign/boycottpuma",
  },
  [PARENT_COMPANIES_NAMES_MAP.carrefour]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "The France-based multinational retailer Carrefour Group is involved in war crimes committed by the Israeli regime of occupation, settler-colonialism and apartheid over the Palestinian people. On 8 March 2022, Carrefour Group announced a new franchise agreement in Israel with Electra Consumer Products and its subsidiary Yenot Bitan, both active in the illegal Israeli settlement enterprise. This decision makes Carrefour complicit in war crimes committed by the Israeli regime of occupation, settler-colonialism and apartheid over the entire Palestinian peopl",
      tr: "Fransız süpermarket zinciri Carrefour, Gazze’de katliam yapan İsrail ordusuna “Sizi güçlendiriyoruz” notuyla erzak yardımı gönderiyor.",
      ar: "تتورط مجموعة كارفور لمتاجر التجزئة متعددة الجنسيات ومقرها فرنسا في جرائم حرب يرتكبها نظام الاحتلال الإسرائيلي والاستعمار الاستيطاني والفصل العنصري ضد الشعب الفلسطيني. في 8 مارس 2022، أعلنت مجموعة كارفور عن اتفاقية امتياز جديدة في إسرائيل مع شركة إليكترا للمنتجات الاستهلاكية. وشركة ينوت بيتان التابعة لها، وكلاهما ناشطان في مشروع الاستيطان الإسرائيلي غير القانوني. وهذا القرار يجعل كارفور متواطئا في جرائم الحرب التي يرتكبها نظام الاحتلال الإسرائيلي والاستعمار الاستيطاني والفصل العنصري على الشعب الفلسطيني بأكمله.",
    },
    infoLink: "https://bdsmovement.net/boycott-carrefour",
  },
  [PARENT_COMPANIES_NAMES_MAP.chevron]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Chevron contributes to financing Israel’s regime of apartheid, settler colonialism and occuption by paying billions of dollars for its gas extraction licences. With its extraction activities, the corporation is also complicit in apartheid Israel’s practice of depriving the Palestinian people of its right to sovereignty over its natural resources, including, among others, through the illegal siege of Gaza and blockade of Gaza’s sea, which Israel imposes by brutal and deadly force. Chevron is also implicated in Israel’s illegal transfer of extracted fossil gas to Egypt through a pipeline illegally crossing the Palestinian Exclusive Economic Zone (EEZ) in Gaza, owing Palestinians millions in transit fees. It is also potentially complicit in Israeli pillage of Palestinian gas reserves off-shore the occupied Gaza Strip, a war crime under international law.",
      tr: "Chevron, gaz çıkarma lisansları için milyarlarca dolar ödeyerek İsrail'in apartheid, yerleşimci sömürgeciliği ve işgal rejiminin finansmanına katkıda bulunuyor. Şirket, maden çıkarma faaliyetleriyle, İsrail'in Gazze'yi yasa dışı kuşatması ve Gazze denizini abluka altına alması da dahil olmak üzere, Filistin halkını kendi doğal kaynakları üzerindeki egemenlik hakkından yoksun bırakan apartheid uygulamasının da suç ortağıdır. acımasız ve ölümcül güç. Chevron aynı zamanda İsrail'in, Gazze'deki Filistin Münhasır Ekonomik Bölgesi'nden (MEB) yasa dışı bir şekilde geçen bir boru hattı aracılığıyla çıkarılan fosil gazını Mısır'a yasa dışı olarak nakletmesine de karışıyor ve Filistinlilere milyonlarca geçiş ücreti borçlu. Aynı zamanda, uluslararası hukuka göre bir savaş suçu olan, İsrail'in işgal altındaki Gazze Şeridi açıklarındaki Filistin gaz rezervlerini yağmalamasında da potansiyel olarak suç ortağıdır.",
      ar: "تساهم شركة شيفرون في تمويل نظام الفصل العنصري الإسرائيلي والاستعمار الاستيطاني والاحتلال من خلال دفع مليارات الدولارات مقابل تراخيص استخراج الغاز. ومن خلال أنشطتها الاستخراجية، فإن الشركة متواطئة أيضًا في ممارسة الفصل العنصري الإسرائيلي المتمثلة في حرمان الشعب الفلسطيني من حقوقه بما في ذلك الحق في السيادة على مواردها الطبيعية، من بين أمور أخرى، من خلال الحصار غير القانوني على غزة والحصار المفروض على بحر غزة، والذي تفرضه إسرائيل بالقوة الوحشية والمميتة. كما أن شركة شيفرون متورطة في النقل غير القانوني الذي تقوم به إسرائيل للغاز الأحفوري المستخرج إلى مصر عبر خط أنابيب يعبر بشكل غير قانوني المنطقة الاقتصادية الخالصة الفلسطينية في غزة، وهي مدينة لفلسطينيين بالملايين. ومن المحتمل أيضًا أن تكون متواطئة في النهب الإسرائيلي لاحتياطيات الغاز الفلسطينية قبالة شاطئ قطاع غزة المحتل، وهي جريمة حرب بموجب القانون الدولي.",
    },
    infoLink: "https://bdsmovement.net/siemens-and-chevron-stop-fueling-apartheid-and-climate-disaster",
  },
  [PARENT_COMPANIES_NAMES_MAP.hyundai]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Bulldozers from companies such as Volvo, Hyundai Heavy Industries, JCB and Caterpillar are an integral part of Israel’s illegal destruction of Palestinian homes and livelihoods. In repeated instances, we have witnessed Israeli occupation forces utilizing these machines to flatten out Palestinian homes, sometimes entire buildings.",
      tr: "Hyundai, JCB, Volvo ve Caterpillar gibi ağır endüstriler şirketlerin buldozerleri, İsrail'in Filistinlilerin evlerini ve geçim kaynaklarını yasadışı bir şekilde yok etmesinin ayrılmaz bir parçası. İsrail işgal güçlerinin bu makineleri Filistinlilerin evlerini, bazen de tüm binalarını yerle bir etmek için kullandığına defalarca tanık olduk.",
      ar: "تشكل الجرافات التابعة لشركات مثل فولفو وهيونداي للصناعات الثقيلة وجي سي بي وكاتربيلر جزءًا لا يتجزأ من التدمير غير القانوني الذي تقوم به إسرائيل لمنازل الفلسطينيين وسبل عيشهم. وفي حالات متكررة، شهدنا قوات الاحتلال الإسرائيلي تستخدم هذه الآلات لتسوية منازل الفلسطينيين، وفي بعض الأحيان مباني بأكملها.",
    },
    infoLink: "https://bdsmovement.net/news/hyundai-heavy-industries-end-complicity-with-apartheid",
  },
  [PARENT_COMPANIES_NAMES_MAP.osem]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "An israeli company",
      tr: "İsraili firma",
      ar: "شركة اسرائيلية",
    },
    infoLink: "https://en.wikipedia.org/wiki/Osem_(company)",
  },
  [PARENT_COMPANIES_NAMES_MAP.pepsico]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "PepsiCo owns SodaStream and 50% of Sabra both of which had taken advantage of the Israeli occupation of Palestine.",
      tr: "PepsiCo, İsrail'in Filistin'i işgalinden yararlanan SodaStream'in ve Sabra'nın %50'sinin sahibidir.",
      ar: "تمتلك شركة بيبسيكو صودا ستريم و50% من شركة صبرا، وكلاهما يستغل الاحتلال الإسرائيلي لفلسطين",
    },
    infoLink: "https://easyboycottisraelguide.wordpress.com/portfolio/pepsico-parent-company/",
  },
  [PARENT_COMPANIES_NAMES_MAP.caf]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "In August 2019, a consortium  led by the Basque company CAF and the Israeli infrastructure company Shapir was selected by Israel’s finance ministry to lead the expansion of the  railway network serving Israel’s illegal colonial settlements in the occupied Palestinian territory, known as the Jerusalem Light Rail (JLR).",
      tr: "Ağustos 2019'da, Bask şirketi CAF ve İsrail altyapı şirketi Shapir liderliğindeki bir konsorsiyum, İsrail maliye bakanlığı tarafından, İsrail'in işgal altındaki Filistin topraklarındaki yasadışı sömürge yerleşimlerine hizmet veren ve Kudüs Hafif Raylı Sistemi olarak bilinen demiryolu ağının genişletilmesine liderlik etmek üzere seçildi (JLR).",
      ar: "في أغسطس 2019، تم اختيار كونسورتيوم بقيادة شركة الباسك CAF وشركة البنية التحتية الإسرائيلية شابير من قبل وزارة المالية الإسرائيلية لقيادة توسيع شبكة السكك الحديدية التي تخدم المستوطنات الاستعمارية الإسرائيلية غير القانونية في الأراضي الفلسطينية المحتلة، والمعروفة باسم قطار القدس الخفيف ( JLR).",
    },
    infoLink: "https://bdsmovement.net/boycott-caf",
  },
  [PARENT_COMPANIES_NAMES_MAP.unilever]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Over the past decade Unilever has invested more than €250 million in Israel's economy.",
      tr: "Geçtiğimiz on yılda Unilever, İsrail ekonomisine 250 milyon Euro'dan fazla yatırım yaptı.",
      ar: "استثمرت الشركة أكثر من 250 مليون يورو في اقتصاد دولة الإحتلال على مدار العقد الماضي",
    },
    infoLink: "https://www.unilever.com/news/press-and-media/press-releases/2022/unilever-reaches-new-business-arrangement-for-ben-jerrys-in-israel/",
  },
  [PARENT_COMPANIES_NAMES_MAP.mondelezInternationalHoldings]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Mondelez international collaborate with Israeli food tech incubator, the kitchen company",
      tr: "Mondelez uluslararası İsrailli gıda teknolojisi kuluçka şirketi the kitchen ile işbirliği yapıyor",
      ar: "تتعاون الشركة مع حاضنة تكنلوجيا الأغذية الإسرائيلية,شركة المطبخ",
    },
    infoLink: "https://ir.mondelezinternational.com/news-releases/news-release-details/mondelez-international-collaborate-israeli-foodtech-incubator",
  },
  [PARENT_COMPANIES_NAMES_MAP.loreal]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "loreal involves deeply in business relations with Israel, despite the latter's continued occupation and apartheid policies against the indigenous Palestinian people.",
      tr: "Loreal, İsrail'in devam eden işgaline ve yerli Filistin halkına karşı apartheid politikalarına rağmen, İsrail'le derin iş ilişkileri içindedir.",
      ar: "تدعم شركة لوريال دولة الإحتلال مع العلم بسياساته العنصرية ضد الشعب الفلسطيني.",
    },
    infoLink: "https://bdsmovement.net/news/l%E2%80%99oreal-makeup-israeli-apartheid",
  },
  [PARENT_COMPANIES_NAMES_MAP.motorola]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Motorola supplies Israel with the military tools it needs to sustain its occupation and oppression of the Palestinians",
      tr: "Motorola, Filistinlilere yönelik işgali ve baskıyı sürdürmek için İsrail'e ihtiyaç duyduğu askeri araçları sağlıyor",
      ar: "تزود موتورولا إسرائيل بالأدوات العسكرية التي تحتاجها للحفاظ على احتلالها وقمعها للفلسطينيين",
    },
    infoLink: "https://bdsmovement.net/news/l%E2%80%99oreal-makeup-israeli-apartheid",
  },
  [PARENT_COMPANIES_NAMES_MAP.pg]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Procter & Gamble opened an R&D center in Tel Aviv, Occupied Palestine, in which it invests $2bn annually.",
      tr: "Procter & Gamble, İşgal Altındaki Filistin'deki Tel Aviv'de yıllık 2 milyar dolar yatırım yaptığı bir Ar-Ge merkezi açtı.",
      ar: "تم افتتاح هذه الشركة في د آند آر في مركز تل أبيب, حيث تدعم الاحتلال سنويا بلمياري دولار",
    },
    infoLink: "https://www.pgcareers.com/global/en/locations/israel",
  },
  [PARENT_COMPANIES_NAMES_MAP.danone]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Danone is a company originally founded in France in 1919. However, it was acquired by Israeli company Tnuva in 1981. Therefore, Danone's influence in Israel is quite significant.",
      tr: "Danone, aslen 1919 yılında Fransa'da kurulmuş bir şirkettir. Ancak, 1981 yılında İsrailli şirket Tnuva tarafından satın alınmıştır. Bu nedenle Danone'un İsrail'deki etkisi oldukça önemlidir.",
      ar: "شركة فرنسية أسست عام 1919, تم الاستحواذ عليها من قبل شركة توا الاسرائيلية حيث إن تأثير الشركة في دولة الإحتلال كبير جدا",
    },
    infoLink: "https://www.ilerigazetesi.com.tr/danone-israil-mali-mi-danone-hangi-ulkenin",
  },
  [PARENT_COMPANIES_NAMES_MAP.henkel]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "German company Henkel invests in Israeli technology company Feelit.",
      tr: "Alman şirketi Henkel, İsrailli teknoloji şirketi Feelit'e yatırım yapıyor.",
      ar: `تستثمر الشركة الألمانية في شركة التكنولوجيا الإسرائيلية
      Feelit.`,
    },
    infoLink: "https://www.henkel.com/press-and-media/press-releases-and-kits/2021-01-07-henkel-invests-in-technology-start-up-feelit-1141594#:~:text=Henkel%20Adhesive%20Technologies%20strengthens%20its,for%20various%20applications%20in%20manufacturing.",
  },
  [PARENT_COMPANIES_NAMES_MAP.hp]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: `HP provides Israel with software & equipment. Let's boycott HP products untilit stops its complicity in the apartheid ofPalestine.`,
      tr: "HP, İsrail'e yazılım ve ekipman sağlıyor. Filistin'deki ırk ayrımı suç ortaklığına son verene kadar HP ürünlerini boykot edelim.",
      ar: "توفر الشركة لاسرائيل البرمجيات والمعدات, لنقاطع منتجات الشركة إلى أن تتوقف عن تواظؤها في الفصل العنصري في فلسطين",
    },
    infoLink: "https://www.foa.org.uk/campaign/boycotthp",
  },
  [PARENT_COMPANIES_NAMES_MAP.reckitt]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Reckitt has a branch in Israel",
      tr: "Reckitt'in İsrail'de bir şubesi bulunmaktadır",
      ar: "شركة reckitt لها فرع موجود في اسرائيل",
    },
    infoLink: "https://en.globes.co.il/en/article-1000040137"
  },
  [PARENT_COMPANIES_NAMES_MAP.kelloggs]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: `In 1997, Kellogg's Institute of Management at Northwestern University, in partnership with Recanati Graduate School of Business Administration at Tel Aviv University, established a global executive master's program in business administration. This program allows executives from international companies to develop their managerial skills in Tel Aviv under the guidance of professors from both institutions, similar to similar programs led by Kellogg's in four other countries (the United States, Canada, Germany, and China). The marketing promotion of this program highlights its importance in facilitating the decision to immigrate to Israel, integrate into its society, and the concept of 'Aliyah,' referring to the perceived right of Jews worldwide to 'return' to the 'Promised Land.`,
      tr: `1997 yılında, Northwestern Üniversitesi'ndeki Kellogg's İşletme Enstitüsü, Tel Aviv Üniversitesi'ndeki Recanati İşletme Yüksek Okulu ile iş birliği yaparak işletme alanında küresel bir yürütme yüksek lisans programı başlattı. Bu program, uluslararası şirketlerden yöneticilere, her iki kurumun öğretim üyeleri rehberliğinde Tel Aviv'de yönetim becerilerini geliştirme fırsatı sunuyor. Kellogg's tarafından dört diğer ülkede yürütülen benzer programlara benzer. Bu programın pazarlama stratejisi, İsrail'e göç kararını kolaylaştırmak, toplumuna entegre olmak ve dünya genelindeki Yahudilerin 'Vaadedilmiş Topraklara' 'geri dönme' hakkına atıfta bulunan 'Aliyah' kavramını vurgular.`,
      ar: `في عام 1997، أسس معهد Kellogg’s للإدارة في جامعة Northwestern الأميركية، بالتعاون مع كلية Recanati للدراسات العليا في إدارة الأعمال في جامعة تل أبيب، برنامجًا عالميًا لماجستير تنفيذي في إدارة الأعمال. يهدف هذا البرنامج إلى تطوير مهارات المدراء التنفيذيين في الشركات العالمية في تل أبيب، ويقدمه أساتذة من المعهد والكلية، ويشبه برامج مماثلة في الولايات المتحدة وكندا وألمانيا والصين التي تديرها Kellogg’s. تسلط الدعاية على أهمية البرنامج في تسهيل قرار الهجرة إلى إسرائيل والاندماج في مجتمعها ومفهوم "العليا"، مشيرة إلى حقوق يهود العالم في العودة إلى "أرض الميعاد".`,
    },
    infoLink: "hhttps://web.archive.org/web/20140706153718/http://www.israelvalley.com/news/2014/05/15/43293/universite-tel-aviv-israel-le-mba-kellogg-recanati-le-meilleur"
  },
  [PARENT_COMPANIES_NAMES_MAP.ferrero]: {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Ferrero has a branch in Israel, located in Holon. This branch contributes to Israel’s economy, provides job opportunities for Israelis, and thus supports the continued existence of Israel.",
      tr: "Ferrero'nun İsrail'de, Holon'da faaliyet gösteren bir şubesi bulunmaktadır. Bu şube, İsrail ekonomisine katkı sağlamakta, İsraillilere iş imkânları sunmakta ve dolayısıyla İsrail işgalci devletinin varlığını desteklemektedir.",
      ar: "تمتلك فيريرو فرعاً في إسرائيل، يقع في مدينة حُولون. يُسهم هذا الفرع في اقتصاد إسرائيل، ويوفّر فرص عمل للإسرائيليين، مما يدعم بالتالي استمرار وجود دولة الاحتلال إسرائيل.",
    },
    infoLink: "https://officesnapshots.com/2023/03/16/ferrero-offices-holon/"
  },
  // NOT ADDED
  "Amazon": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Amazon and Google are working together on Project Nimbus. \nProject Nimbus is a $1.2bn contract to provide cloud services for the Israeli military and government. This technology allows for further surveillance of and unlawful data collection on Palestinians, and facilitates expansion of Israel’s illegal settlements on Palestinian land.",
      tr: "",
      ar: "",
    },
    infoLink: "https://www.theguardian.com/commentisfree/2021/oct/12/google-amazon-workers-condemn-project-nimbus-israeli-military-contract"
  },
  "AXA": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "AXA invests in Israeli banks, which finance the theft of Palestinian land and natural resources.",
      tr: "",
      ar: "",
    },
    infoLink: "https://bdsmovement.net/axa-divest"
  },
  "Bank of America": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Bank of America was part of a $500m loan deal with a consortium of lenders, into Elbit Systems Ltd, the Israeli weapons manufacturer.",
      tr: "",
      ar: "",
    },
    infoLink: "https://breachmedia.ca/revealed-bmo-bankrolled-israeli-weapons-maker-with-a-90m-loan/"
  },
  "BNP Paribas": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "BNP Paribas was part of a $500m loan deal with a consortium of lenders, into Elbit Systems Ltd, the Israeli weapons manufacturer.",
      tr: "",
      ar: "",
    },
    infoLink: "https://breachmedia.ca/revealed-bmo-bankrolled-israeli-weapons-maker-with-a-90m-loan/"
  },
  "Booking.com": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Booking.com allows listings in occupied Palestinian territory, and after announcing it will remove 200 of such listings, it reversed its decision, showing it is knowingly complicit in Israels occupation.",
      tr: "",
      ar: "",
    },
    infoLink: "https://www.aljazeera.com/news/2022/10/5/is-booking-com-reversal-palestine-corporate-hypocrisy"
  },
  "Chanel": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Chanel reportedly said it will make a donation of $4 million to organizations providing emergency humanitarian aid to southern Israel",
      tr: "",
      ar: "",
    },
    infoLink: "https://www.algemeiner.com/2023/10/18/chanel-tory-burch-others-fashion-donate-help-israelis-impacted-hamas-war/"
  },
  "Dell": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Michael Dell, the founder of Dell, is a member of the Friends of the IDF, which raises money for the Israeli military. He has donated millions so far and continues to do so.",
      tr: "",
      ar: "",
    },
    infoLink: "https://www.bloomberg.com/news/articles/2014-11-07/ellison-adelson-donate-to-israeli-soldiers?leadSource=uverify%20wall#xj4y7vzkg"
  },
  "Disney": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "",
      tr: "",
      ar: "",
    },
    infoLink: "https://en.globes.co.il/en/article-disney-regional-manager-well-invest-in-original-productions-1001414449"
  },
  "Google": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Amazon and Google are working together on Project Nimbus. \n\nProject Nimbus is a $1.2bn contract to provide cloud services for the Israeli military and government. This technology allows for further surveillance of and unlawful data collection on Palestinians, and facilitates expansion of Israel’s illegal settlements on Palestinian land.",
      tr: "",
      ar: "",
    },
    infoLink: "https://www.theguardian.com/commentisfree/2021/oct/12/google-amazon-workers-condemn-project-nimbus-israeli-military-contract"
  },
  "H&M": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "H&M maintains a notable presence in Israel with approximately 20 stores, signifying its substantial retail footprint and market engagement in the region.",
      tr: "",
      ar: "",
    },
    infoLink: "https://www.hm.com/is/store-locator/israel/"
  },
  "Intel": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Intel has longstanding operations in Israel, deeply involved in the technology sector, particularly in chip manufacturing. Recently, Intel withdrew from the Web Summit, Europe's largest technology conference, in response to the event leader's remarks criticizing Western support for Israel following attacks. This decision highlights the company's stance and its significant business interests in Israel.",
      tr: "",
      ar: "",
    },
    infoLink: "https://fortune.com/2023/10/19/intel-siemens-web-summit-withdrew-israel-hamas-attack/"
  },
  "LIDL": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Dieter Schwarz is the owner of LIDL.\n\nDieter Schwarz's family office fund, Zukunftsfonds Heilbronn (ZFHN), is gearing up to invest millions in Israeli technologies. The fund plans to invest between $500,000 and several million dollars in Israeli seed and pre-seed companies, focusing on areas like IoT, robotics, autonomous driving, energy systems, new materials, and biotech.",
      tr: "",
      ar: "",
    },
    infoLink: "https://en.globes.co.il/en/article-german-billionaire-seeks-israeli-high-tech-investments-1001238937"
  },
  "Nvidia": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "Nvidia is building a supercomputer for Israel, which will undoubtedly be used to continue its oppression of Palestinians and enforce their apartheid.",
      tr: "",
      ar: "",
    },
    infoLink: "https://www.reuters.com/technology/nvidia-build-israeli-supercomputer-ai-demand-soars-2023-05-29/"
  },
  "Paypal": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "The world’s biggest online payment platform, PayPal, won’t serve Palestinians living in Israeli-occupied West Bank and the Gaza Strip. ",
      tr: "",
      ar: "",
    },
    infoLink: "https://actions.eko.org/a/paypal-stop-discriminating-against-palestinians#:~:text=The%20world"
  },
  "LVMH": {
    isBoycott: true,
    isNeutral: false,
    reason: {
      en: "The chairman and CEO of LMVH Moet Hennessy-Louis Vuitton, and the world’s richest man according to Forbes, has invested in cybersecurity firm, Wiz, the Israeli maker of cloud security solutions for enterprises.",
      tr: "",
      ar: "",
    },
    infoLink: "https://www.timesofisrael.com/luxury-goods-magnate-bernard-arnault-invests-in-israeli-cybersecurity-firm-wiz/d"
  },
  // TILL HERE
  [PARENT_COMPANIES_NAMES_MAP.karakanAilesi]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.malatyaPazariPalanci]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.sedes]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.turkiyeCumhuriyetMerkezBankasi]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.arifoglu]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.yildizHolding]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.tamekGrupGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.ozsoyUnluMamulleri]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.betaGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.dogasal]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.a101]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.dogusFoodandBeverageProduction]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.mavi]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.turkTelekomunikasyon]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.littleCaesars]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.turkcellIletisimHizmetleri]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.tatGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.bim]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.lcWaikiki]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kigiliGiyim]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.hatemogluTekstil]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.anadoluGrubu]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.jakamenTekstil]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.orkaHolding]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kotonMagazacilikTekstil]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.suvariGiyim]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.imzaMagazacilik]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.zeyrekTekstil]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.erogluHolding]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.gezerAyakkabi]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kingPauloTekstil]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.forelliTekstil]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.ydsAyakkabi]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.afiaGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.solenCikolataGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.erisGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.vipGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.yildizHolding]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.turgutAydinHolding]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.trendyol]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.passaport]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kahveDunyasi]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.sleepy]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kopasKozmetik]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.danaDisTicaret]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kervanGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.burcuGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.selvaGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.aytacGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kevserGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.tarisZeytin]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.zadeVitalGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.eczacibasiTuketim]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.orucogluYag]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.puretex]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.deha]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.uludag]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.hamidiyeSu]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kayisdagiSu]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.aytacSu]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.nigdeGazozu]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.beypazariMadenSuyu]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kizilay]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.doganay]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.nazo]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.aroma]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.meyoz]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.hunkarGazoz]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.evyap]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.naturalive]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.duru]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.birlesikHolding]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.bingo]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.mintax]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.abc]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.peros]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.ersag]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.viking]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.flaminfo]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.coty]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kirecburnu]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.mio]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.hes]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.paksoy]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.etiMatik]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.telekom]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.vestel]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.casper]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.ozturkGroup]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.bip]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.tamek]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.drOetker]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.almarai]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.bimeks]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.vatanBilgisayar]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kaanTelefon]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.arcelik]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.beko]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.arzum]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.king]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.sinbo]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.goldMaster]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.tukas]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.sutas]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.yaylaAgroGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.erPilicEntegre]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.kervansaray]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.tadella]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.merterHelva]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.caykur]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.sagraGrup]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.altinbasHolding]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.nuhunAnkaraMakarnasi]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.akdoGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.erislerGida]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.gloriaJeans]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.nevcoHolding]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.altinbasHolding]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.seli]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.dismes]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.lilaGroup]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.oneK]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.pizzaBulls]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.puntoFa]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.ozonGiyim]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.boyner]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.ebebek]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.ulker]: {
    isBoycott: false,
    isNeutral: false,
  },
  [PARENT_COMPANIES_NAMES_MAP.onlem]: {
    isBoycott: false,
    isNeutral: false,
  },
};

export const data = shuffleArray([
  {
    en: {
      name: "Himalaya",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    },
    tr: {
      name: "Himalaya",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    },
    ar: {
      name: "هيمالايا",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Benri",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    },
    tr: {
      name: "Benri",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    },
    ar: {
      name: "بينري",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Bee Beauty",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    },
    tr: {
      name: "Bee Beauty",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    },
    ar: {
      name: "بي بيوتي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Doğuş",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.karakanAilesi,
      alternatives: [],
    },
    tr: {
      name: "Doğuş",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.karakanAilesi,
      alternatives: [],
    },
    ar: {
      name: "دوغوش",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.karakanAilesi,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Çerezya",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.malatyaPazariPalanci,
      alternatives: [],
    },
    tr: {
      name: "Çerezya",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.malatyaPazariPalanci,
      alternatives: [],
    },
    ar: {
      name: "تشيريزيا",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.malatyaPazariPalanci,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Gratis",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    },
    tr: {
      name: "Gratis",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    },
    ar: {
      name: "غراتيس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sedes,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Troy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turkiyeCumhuriyetMerkezBankasi,
      alternatives: [],
    },
    tr: {
      name: "Troy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turkiyeCumhuriyetMerkezBankasi,
      alternatives: [],
    },
    ar: {
      name: "تروي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turkiyeCumhuriyetMerkezBankasi,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Arifoğlu",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.arifoglu,
      alternatives: [],
    },
    tr: {
      name: "Arifoğlu",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.arifoglu,
      alternatives: [],
    },
    ar: {
      name: "عارف اوغلو",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.arifoglu,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Komili",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    tr: {
      name: "Komili",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    ar: {
      name: "كوميلي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Tamek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamekGrupGida,
      alternatives: [],
    },
    tr: {
      name: "Tamek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamekGrupGida,
      alternatives: [],
    },
    ar: {
      name: "تاميك",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamekGrupGida,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Lezzethane",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ozsoyUnluMamulleri,
      alternatives: [],
    },
    tr: {
      name: "Lezzethane",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ozsoyUnluMamulleri,
      alternatives: [],
    },
    ar: {
      name: "ليزيت هانة ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ozsoyUnluMamulleri,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Panadol",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Panadol",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "بانادول ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Mest çay",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.betaGida,
      alternatives: [],
    },
    tr: {
      name: "Mest çay",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.betaGida,
      alternatives: [],
    },
    ar: {
      name: "شاي ميست ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.betaGida,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Doğasal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dogasal,
      alternatives: [],
    },
    tr: {
      name: "Doğasal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dogasal,
      alternatives: [],
    },
    ar: {
      name: "دوغاسال ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dogasal,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "A101",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.a101,
      alternatives: [],
    },
    tr: {
      name: "A101",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.a101,
      alternatives: [],
    },
    ar: {
      name: "A101 ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.a101,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Patos",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dogusFoodandBeverageProduction,
      alternatives: [],
    },
    tr: {
      name: "Patos",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dogusFoodandBeverageProduction,
      alternatives: [],
    },
    ar: {
      name: "باتوس ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dogusFoodandBeverageProduction,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Mavi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mavi,
      alternatives: [],
    },
    tr: {
      name: "Mavi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mavi,
      alternatives: [],
    },
    ar: {
      name: "مافي ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mavi,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Türk Telekom",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turkTelekomunikasyon,
      alternatives: [],
    },
    tr: {
      name: "Türk Telekom",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turkTelekomunikasyon,
      alternatives: [],
    },
    ar: {
      name: "تورك تيليكوم",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turkTelekomunikasyon,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Migros",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.anadoluGrubu,
      alternatives: [],
    },
    tr: {
      name: "Migros",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.anadoluGrubu,
      alternatives: [],
    },
    ar: {
      name: "ميجروس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.anadoluGrubu,
      alternatives: [],
    }
  }, 
  {
    en: {
      name: "Turkcell",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turkcellIletisimHizmetleri,
      alternatives: [],
    },
    tr: {
      name: "Turkcell",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turkcellIletisimHizmetleri,
      alternatives: [],
    },
    ar: {
      name: "توركسيل",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turkcellIletisimHizmetleri,
      alternatives: [],
    }
  }, 
    {
        en: {
          name: "DeFacto",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.ozonGiyim,
          alternatives: [],
        },
        tr: {
          name: "DeFacto",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.ozonGiyim,
          alternatives: [],
        },
        ar: {
          name: "ديفاكتو",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.ozonGiyim,
          alternatives: [],
        }
      }, 
    {
        en: {
          name: "Sensodyne",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
          alternatives: [],
        },
        tr: {
          name: "Sensodyne",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
          alternatives: [],
        },
        ar: {
          name: "Sensodyne",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
          alternatives: [],
        }
      },  
    {
    en: {
      name: "Aquafresh (diş macunu)",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Aquafresh (diş macunu)",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Aquafresh (diş macunu)",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Parodontax",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Parodontax",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Parodontax",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
  {
      en: {
        name: "Coldrex",
        parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
        alternatives: [],
      },
      tr: {
        name: "Coldrexy",
        parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
        alternatives: [],
      },
      ar: {
        name: "Coldrexy",
        parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
        alternatives: [],
      }
    },
    {
        en: {
          name: "Solo",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.eczacibasiTuketim,
          alternatives: [],
        },
        tr: {
          name: "Solo",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.eczacibasiTuketim,
          alternatives: [],
        },
        ar: {
          name: "Solo",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.eczacibasiTuketim,
          alternatives: [],
        }
      },
{
    en: {
      name: "Corega",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Coregaey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Coregaey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Advair",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Advairey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Advairey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Amoxicillin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Amoxicillin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Amoxicillin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Andrews Liver Salts",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Andrews Liver Salts",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Andrews Liver Salts",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Arixtra",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Arixtray",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Arixtray",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Arranon",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Arranony",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Arranony",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Atriance",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Atriance",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Atriance",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Augmentin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Augmentin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Augmentin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Avamys",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Avamysey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Avamysey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Avodart",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Avodarty",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Avodarty",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Beecham’s Pills",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Beecham’s Pills",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Beecham’s Pills",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Bupropion",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Bupropion",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Bupropion",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Dutasteride/tamsulosin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Dutasteride/tamsulosin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Dutasteride/tamsulosin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Epivir",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Epivirey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Epivirey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Etacstil",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Etacstil",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Etacstil",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
  {
      en: {
        name: "Flixotide",
        parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
        alternatives: [],
      },
      tr: {
        name: "Flixotide",
        parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
        alternatives: [],
      },
      ar: {
        name: "Flixotide",
        parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
        alternatives: [],
      }
    },
    {
        en: {
          name: "Lies",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.arcelik,
          alternatives: [],
        },
        tr: {
          name: "Lies",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.arcelik,
          alternatives: [],
        },
        ar: {
          name: "Lies",
          parentCompany: PARENT_COMPANIES_NAMES_MAP.arcelik,
          alternatives: [],
        }
      },
{
    en: {
      name: "Flonase",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Flonasey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Flonasey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Flovent",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Floventy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Floventy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Geritol",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Geritoly",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Geritoly",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Hepatyrix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Hepatyrix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Hepatyrix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Heptovir",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Heptovir",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Heptovir",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Benzedrine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Benzedrine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Benzedrine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Horlicks",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Horlicks",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Horlicks",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Lamictal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Lamictal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Lamictal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Nicorette",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Nicorette",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Nicorette",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Niquitin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Niquitin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Niquitin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Omega-3 acid",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Omega-3 acid",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Omega-3 acid",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Panadol night",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Panadol night",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Panadol night",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Pandemrix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Pandemrix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Pandemrix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Promacta",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Promacta",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Promacta",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Ralgex",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Ralgexey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Ralgexey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Relenza",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Relenzay",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Relenzay",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Revolade",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Revolade",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Revolade",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Salbutamol",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Salbutamol",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Salbutamol",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Sensodyne",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Sensodyne",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Sensodyne",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Seretide",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Seretide",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Seretide",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Serlipet",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Serlipet",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Serlipet",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Solpadeine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Solpadeine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Solpadeine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Sumatriptan/naproxen sodium",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Sumatriptan/naproxen sodium",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Sumatriptan/naproxen sodium",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Synthol (ağız yıkama)",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Synthol (ağız yıkama)",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Synthol (ağız yıkama)",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Topotecan",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Topotecan",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Topotecan",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Tums",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Tumssney",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Tumssney",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Twinrix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Twinrixy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Twinrixy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Tykerb",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Tykerbey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Tykerbey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Tyverb",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Tyverbey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Tyverbey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Valtrex",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Valtrexy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Valtrexy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Veramyst",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Veramyst",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Veramyst",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Zantac",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Zantacey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Zantacey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Zeffix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Zeffixey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Zeffixey",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Zelitrex",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Zelitrex",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Zelitrex",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
{
    en: {
      name: "Zofran",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    tr: {
      name: "Zofraney",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    },
    ar: {
      name: "Zofraney",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.GSK,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dr. Oetker",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.drOetker,
      alternatives: [],
    },
    tr: {
      name: "Dr. Oetker",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.drOetker,
      alternatives: [],
    },
    ar: {
      name: "Dr. Oetker",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.drOetker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Almarai",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.drOetker,
      alternatives: [],
    },
    tr: {
      name: "Almarai",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.drOetker,
      alternatives: [],
    },
    ar: {
      name: "المراعي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.drOetker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Disney",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.disney,
      alternatives: [],
    },
    tr: {
      name: "Disney",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.disney,
      alternatives: [],
    },
    ar: {
      name: "Disney",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.disney,
      alternatives: [],
    }
  },
  {
    en: {
      name: "National Geographic",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.disney,
      alternatives: [],
    },
    tr: {
      name: "National Geographic",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.disney,
      alternatives: [],
    },
    ar: {
      name: "National Geographic",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.disney,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Marks and Spencer",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.marksAndSpencer,
      alternatives: [],
    },
    tr: {
      name: "Marks and Spencer",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.marksAndSpencer,
      alternatives: [],
    },
    ar: {
      name: "Marks and Spencer",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.marksAndSpencer,
      alternatives: [],
    }
  },
  {
    en: {
      name: "General Motors",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    tr: {
      name: "General Motors",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    ar: {
      name: "General Motors",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cadillac",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    tr: {
      name: "Cadillac",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    ar: {
      name: "Cadillac",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Wuling",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    tr: {
      name: "Wuling",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    ar: {
      name: "Wuling",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Baojun",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    tr: {
      name: "Baojun",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    ar: {
      name: "Baojun",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    }
  },
  {
    en: {
      name: "GMC",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    tr: {
      name: "GMC",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    ar: {
      name: "GMC",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Chevrolet",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    tr: {
      name: "Chevrolet",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    ar: {
      name: "Chevrolet",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Buick",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    tr: {
      name: "Buick",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    },
    ar: {
      name: "Buick",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.generalMotors,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Johnson and Johnson",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: ["BeeBeauty"],
    },
    tr: {
      name: "Johnson and Johnson",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: ["BeeBeauty"],
    },
    ar: {
      name: "جونسون اند جونسون",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: ["BeeBeauty"],
    }
  },
  {
    en: {
      name: "Bengay",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Bengay",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Bengay",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Carefree",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: ["Sleepy","Molped","Softy"],
    },
    tr: {
      name: "Carefree",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: ["Sleepy","Molped","Softy"],
    },
    ar: {
      name: "كير فري",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: ["Sleepy","Molped","Softy"],
    }
  },
  {
    en: {
      name: "Clean & Clear",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Clean & Clear",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "كلين اند كلير",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Desitin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Desitin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Desitin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Johnson’s Baby",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: ["Dalin"],
    },
    tr: {
      name: "Johnson’s Baby",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: ["Dalin"],
    },
    ar: {
      name: "جونسون بيبي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: ["Dalin"],
    }
  },
  {
    en: {
      name: "Kompensan",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Kompensan",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Kompensan",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Le Petit Marseillais",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Le Petit Marseillais",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Le Petit Marseillais",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Listerine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Listerine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Listerine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Neutrogena",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Neutrogena",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Neutrogena",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nicorette",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Nicorette",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Nicorette",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "O.B.",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "O.B.",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "O.B.",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "OTC",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "OTC",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "OTC",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Piz Buin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Piz Buin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Piz Buin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Roc",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Roc",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Roc",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Unisom",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Unisom",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Unisom",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Visine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    tr: {
      name: "Visine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    },
    ar: {
      name: "Visine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.jAndJ,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Anti-Ox",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Anti-Ox",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Anti-Ox",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cazz",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Cazzemi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Cazz",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cmix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Cmix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Cmix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dior",
      parentCompany: "LVMH",
      alternatives: [],
    },
    tr: {
      name: "Dior",
      parentCompany: "LVMH",
      alternatives: [],
    },
    ar: {
      name: "Dior",
      parentCompany: "LVMH",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fendi",
      parentCompany: "LVMH",
      alternatives: [],
    },
    tr: {
      name: "Fendi",
      parentCompany: "LVMH",
      alternatives: [],
    },
    ar: {
      name: "Fendi",
      parentCompany: "LVMH",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Givenchy",
      parentCompany: "LVMH",
      alternatives: [],
    },
    tr: {
      name: "Givenchy",
      parentCompany: "LVMH",
      alternatives: [],
    },
    ar: {
      name: "Givenchy",
      parentCompany: "LVMH",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cofeeto",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Cofeeto",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Cofeeto",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fruitam",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Fruitam",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Fruitam",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fruiter",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Fruiter",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Fruiter",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Minido",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Minidoi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Minido",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Samba",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Sambami",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Samba",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Samba Pio",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Samba Pio",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Samba Pio",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Slop",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Slopemi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Slop",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Slop Coolmix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Slop Coolmix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Slop Coolmix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Slop Redmix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Slop Redmix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Slop Redmix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Slop Tropix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Slop Tropix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Slop Tropix",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tambenlik",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Tambenlik",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Tambenlik",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tamek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Tamekmi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Tamek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tamekids",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Tamekids",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Tamekids",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tamekse Koy Sepete",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Tamekse Koy Sepete",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Tamekse Koy Sepete",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tammy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Tammymi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Tammy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tango",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Tangomi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Tango",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tilt",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Tiltemi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Tilt",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Türtamek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Türtamek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Türtamek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ustasından Lezzetler",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    tr: {
      name: "Ustasından Lezzetler",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    },
    ar: {
      name: "Ustasından Lezzetler",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.tamek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bohemi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Bohemi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Bohemi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bohemia Cam",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Bohemia Cam",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Bohemia Cam",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Bohemia Kristal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Bohemia Kristal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Bohemia Kristal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Bohemian",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Bohemian",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Bohemian",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Bohemian Quality",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Bohemian Quality",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Bohemian Quality",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Bohemya",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Bohemya",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Bohemya",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Bohemyan",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Bohemyan",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Bohemyan",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Coco Home",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Coco Home",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Coco Home",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Ma-Co Madame Coco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Ma-Co Madame Coco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Ma-Co Madame Coco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Madame Coco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Madame Coco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Madame Coco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Madame Coco & Co.",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Madame Coco & Co.",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Madame Coco & Co.",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Madame Coco Bohemian Quality",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Madame Coco Bohemian Quality",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Madame Coco Bohemian Quality",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Quartz Koruma Kalkanı",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Quartz Koruma Kalkanı",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Quartz Koruma Kalkanı",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Repertoire",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Repertoire",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Repertoire",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Zirconium Bio",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Zirconium Bio",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Zirconium Bio",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
{
    en: {
      name: "Zirconiumbio",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    tr: {
      name: "Zirconiumbio",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    },
    ar: {
      name: "Zirconiumbio",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.deha,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Önlem bebek bezi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.onlem,
      alternatives: [],
    },
    tr: {
      name: "Önlem bebek bezi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.onlem,
      alternatives: [],
    },
    ar: {
      name: "Önlem bebek bezi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.onlem,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kireçburnu",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kirecburnu,
      alternatives: [],
    },
    tr: {
      name: "Kireçburnu",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kirecburnu,
      alternatives: [],
    },
    ar: {
      name: "Kireçburnu",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kirecburnu,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Baby Turco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.puretex,
      alternatives: [],
    },
    tr: {
      name: "Baby Turco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.puretex,
      alternatives: [],
    },
    ar: {
      name: "Baby Turco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.puretex,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Lady Turco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.puretex,
      alternatives: [],
    },
    tr: {
      name: "Lady Turco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.puretex,
      alternatives: [],
    },
    ar: {
      name: "Lady Turco",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.puretex,
      alternatives: [],
    }
  },
  {
    en: {
      name: "E bebek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ebebek,
      alternatives: [],
    },
    tr: {
      name: "E bebek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ebebek,
      alternatives: [],
    },
    ar: {
      name: "E bebek",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ebebek,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dido",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Dido",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "ديدو",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Godiva",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    tr: {
      name: "Godiva",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    ar: {
      name: "Godiva",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bizim",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Bizim",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Bizim",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bizim Yağ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Bizim Yağ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Bizim Yağ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hüner",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Hüner",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Hüner",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Halk",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Halk",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Halk",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Luna",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Luna",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Luna",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Niva",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Niva",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Niva",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sabah",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Sabah",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Sabah",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Teremyağ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Teremyağ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Teremyağ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ülker Bizim Yağ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Ülker Bizim Yağ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Ülker Bizim Yağ",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Yayla",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    tr: {
      name: "Yayla",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    },
    ar: {
      name: "Yayla",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ulker,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Coty",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.coty,
      alternatives: [],
    },
    tr: {
      name: "Coty",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.coty,
      alternatives: [],
    },
    ar: {
      name: "Coty",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.coty,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Koleston",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.coty,
      alternatives: [],
    },
    tr: {
      name: "Koleston",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.coty,
      alternatives: [],
    },
    ar: {
      name: "Koleston",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.coty,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Wella",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.coty,
      alternatives: [],
    },
    tr: {
      name: "Wella",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.coty,
      alternatives: [],
    },
    ar: {
      name: "Wella",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.coty,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kahve Dünyası",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kahveDunyasi,
      alternatives: [],
    },
    tr: {
      name: "Kahve Dünyası",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kahveDunyasi,
      alternatives: [],
    },
    ar: {
      name: "Kahve Dünyası",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kahveDunyasi,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Pasaport Pizza",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.passaport,
      alternatives: [],
    },
    tr: {
      name: "Pasaport Pizza",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.passaport,
      alternatives: [],
    },
    ar: {
      name: "باسابورت بيتزا",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.passaport,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sleepy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sleepy,
      alternatives: [],
    },
    tr: {
      name: "Sleepy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sleepy,
      alternatives: [],
    },
    ar: {
      name: "سليبي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.passaport,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sleepy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sleepy,
      alternatives: [],
    },
    tr: {
      name: "Sleepy",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.sleepy,
      alternatives: [],
    },
    ar: {
      name: "سليبي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.passaport,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dalin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kopasKozmetik,
      alternatives: [],
    },
    tr: {
      name: "Dalin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kopasKozmetik,
      alternatives: [],
    },
    ar: {
      name: "ديلان",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kopasKozmetik,
      alternatives: [],
    }
  },
  {
    en: {
      name: "English Home",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turgutAydinHolding,
      alternatives: [],
    },
    tr: {
      name: "English Home",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turgutAydinHolding,
      alternatives: [],
    },
    ar: {
      name: "English Home",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.turgutAydinHolding,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Opet",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ozturkGroup,
      alternatives: [],
    },
    tr: {
      name: "Opet",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ozturkGroup,
      alternatives: [],
    },
    ar: {
      name: "أوبيت",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ozturkGroup,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Boyner",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.boyner,
      alternatives: [],
    },
    tr: {
      name: "Boyner",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.boyner,
      alternatives: [],
    },
    ar: {
      name: "بوينر",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.boyner,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Trendyol",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.trendyol,
      alternatives: [],
    },
    tr: {
      name: "Trendyol",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.trendyol,
      alternatives: [],
    },
    ar: {
      name: "ترنديول",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.trendyol,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Buzdağı",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.seli,
      alternatives: [],
    },
    tr: {
      name: "Buzdağı",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.seli,
      alternatives: [],
    },
    ar: {
      name: "Buzdağı",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.seli,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Pizza bulls",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.pizzaBulls,
      alternatives: [],
    },
    tr: {
      name: "Pizza bulls",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.pizzaBulls,
      alternatives: [],
    },
    ar: {
      name: "Pizza bulls",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.pizzaBulls,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Popeyes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.popeyes,
      alternatives: [],
    },
    tr: {
      name: "Popeyes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.popeyes,
      alternatives: [],
    },
    ar: {
      name: "Popeyes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.popeyes,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Leis",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.arcelik,
      alternatives: [],
    },
    tr: {
      name: "Leis",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.arcelik,
      alternatives: [],
    },
    ar: {
      name: "Leis",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.arcelik,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Highgenic",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.oneK,
      alternatives: [],
    },
    tr: {
      name: "Highgenic",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.oneK,
      alternatives: [],
    },
    ar: {
      name: "Highgenic",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.oneK,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mahindra",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mahindra,
      alternatives: [],
    },
    tr: {
      name: "Mahindra",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mahindra,
      alternatives: [],
    },
    ar: {
      name: "Mahindra",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mahindra,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Saangyong",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mahindra,
      alternatives: [],
    },
    tr: {
      name: "Saangyong",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mahindra,
      alternatives: [],
    },
    ar: {
      name: "Saangyong",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mahindra,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sofia",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.lilaGroup,
      alternatives: [],
    },
    tr: {
      name: "Sofia",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.lilaGroup,
      alternatives: [],
    },
    ar: {
      name: "Sofia",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.lilaGroup,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dimes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dismes,
      alternatives: [],
    },
    tr: {
      name: "Dimes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dismes,
      alternatives: [],
    },
    ar: {
      name: "Dimes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dismes,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Adidas",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.adidas,
      alternatives: ["Lumberjack","Lescon","Jump"],
    },
    tr: {
      name: "Adidas",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.adidas,
      alternatives: ["Lumberjack","Lescon","Jump"],
    },
    ar: {
      name: "أديداس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.adidas,
      alternatives: ["Lumberjack","Lescon","Jump"],
    }
  },
  {
    en: {
      name: "Nike",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nike,
      alternatives: [],
    },
    tr: {
      name: "Nike",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nike,
      alternatives: [],
    },
    ar: {
      name: "نايكي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nike,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Zara",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.zara,
      alternatives: ["Adl"],
    },
    tr: {
      name: "Zara",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.zara,
      alternatives: ["Adl"],
    },
    ar: {
      name: "زارا",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.zara,
      alternatives: ["Adl"],
    }
  },
  {
    en: {
      name: "Coca Cola",
      parentCompany: "Coca Cola",
      alternatives: ["Bicola","Spiro spathis"],
    },
    tr: {
      name: "Coca Cola",
      parentCompany: "Coca Cola",
      alternatives: ["Bicola","Spiro spathis"],
    },
    ar: {
        name: "كوكاكولا",
      parentCompany: "Coca Cola",
        alternatives: ["Bicola","Spiro spathis"],
    }
  },
  {
    en: {
      name: "Sprite",
      parentCompany: "Coca Cola",
      alternatives: ["çamlıca"]
    },
    tr: {
      name: "Sprite",
      parentCompany: "Coca Cola",
      alternatives: ["çamlıca"]
    },
    ar: {
      name: "سبرايت",
      parentCompany: "Coca Cola",
      alternatives: ["çamlıca"]
    }
  },
  {
    en: {
      name: "Cappy",
      parentCompany: "Coca Cola",
      alternatives: []
    },
    tr: {
      name: "Cappy",
      parentCompany: "Coca Cola",
      alternatives: []
    },
    ar: {
      name: "عصير كابي",
      parentCompany: "Coca Cola",
      alternatives: []
    }
  },
  {
    en: {
      name: "Appletiser",
      parentCompany: "Coca Cola",
      alternatives: []
    },
    tr: {
      name: "Appletiser",
      parentCompany: "Coca Cola",
      alternatives: []
    },
    ar: {
      name: "Appletiser",
      parentCompany: "Coca Cola",
      alternatives: []
    }
  },
  {
    en: {
      name: "Fanta",
      parentCompany: "Coca Cola",
      alternatives: ["Uludağ Frutti Extra"],
    },
    tr: {
      name: "Fanta",
      parentCompany: "Coca Cola",
      alternatives: ["Uludağ Frutti Extra"],
    },
    ar: {
      name: "فانتا",
      parentCompany: "Coca Cola",
      alternatives: ["Uludağ Frutti Extra"],
    }
  },
  {
    en: {
      name: "Fuse Tea",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Fuse Tea",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "فوزي تي",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fuze Tea",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Fuze Tea",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "Fuze Tea",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ciel",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Ciel",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "سيل",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Doğadan",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Doğadan",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "دوغادان",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Diet Coke",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Diet Coke",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "دايت كوك",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Costa Coffee",
      parentCompany: "Coca Cola",
      alternatives: ["Nevada"],
    },
    tr: {
      name: "Costa Coffee",
      parentCompany: "Coca Cola",
      alternatives: ["Nevada"],
    },
    ar: {
      name: "كوستا كوفي",
      parentCompany: "Coca Cola",
      alternatives: ["Nevada"],
    }
  },
  {
    en: {
      name: "Barq's",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Barq's",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "باركس",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dasani",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Dasani",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "داساني",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Minute Maid",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Minute Maid",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "مينيت مايد",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Glaceau Smartwater",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Glaceau Smartwater",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "جلاسو سمارت ووتر",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Odwalla",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Odwalla",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "أودوالا",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fairlife",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Fairlife",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "فيرلايف",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "BODYARMOR",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "BODYARMOR",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "بودي ارمور",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Simply Orange",
      parentCompany: "Coca Cola",
      alternatives: ["Arko","Derby"],
    },
    tr: {
      name: "Simply Orange",
      parentCompany: "Coca Cola",
      alternatives: ["Arko","Derby"],
    },
    ar: {
      name: "سيمبلي أورانج",
      parentCompany: "Coca Cola",
      alternatives: ["Arko","Derby"],
    }
  },
  {
    en: {
      name: "Powerade",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Powerade",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "باويراد",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Honest Tea",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Honest Tea",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "أونيست تي",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fuze Beverage",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Fuze Beverage",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "فوزي بيفيرج",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Peace Tea",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    tr: {
      name: "Peace Tea",
      parentCompany: "Coca Cola",
      alternatives: [],
    },
    ar: {
      name: "بيس تي",
      parentCompany: "Coca Cola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Starbucks",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.starbucks,
      alternatives: ["Kahve Dünyası", "Nevada", "Gloria Jeans"],
    },
    tr: {
      name: "Starbucks",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.starbucks,
      alternatives: ["Kahve Dünyası", "Nevada", "Gloria Jeans"],
    },
    ar: {
      name: "ستاربكس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.starbucks,
      alternatives: ["Kahve Dünyası", "Nevada", "Gloria Jeans"],
    }
  },
  {
    en: {
      name: "McDonald's",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mcdonalds,
      alternatives: ["KöfteciYusuf"],
    },
    tr: {
      name: "McDonald's",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mcdonalds,
      alternatives: ["KöfteciYusuf"],
    },
    ar: {
      name: "McDonald's",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.mcdonalds,
      alternatives: ["KöfteciYusuf"],
    }
  },
  {
    en: {
      name: "Burger King",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.burgerKing,
      alternatives: ["Cemil usta"],
    },
    tr: {
      name: "Burger King",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.burgerKing,
      alternatives: ["Cemil usta"],
    },
    ar: {
      name: "Burger King",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.burgerKing,
      alternatives: ["Cemil usta"],
    }
  },
  {
    en: {
      name: "Domino's Pizza",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dominos,
      alternatives: [],
    },
    tr: {
      name: "Domino's Pizza",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dominos,
      alternatives: [],
    },
    ar: {
      name: "Domino's Pizza",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.dominos,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Pizza Hut",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yumBrandsInc,
      alternatives: [],
    },
    tr: {
      name: "Pizza Hut",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yumBrandsInc,
      alternatives: [],
    },
    ar: {
      name: "Pizza Hut",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yumBrandsInc,
      alternatives: [],
    }
  },
  {
    en: {
      name: "KFC",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yumBrandsInc,
      alternatives: [],
    },
    tr: {
      name: "KFC",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yumBrandsInc,
      alternatives: [],
    },
    ar: {
      name: "كنتاكي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yumBrandsInc,
      alternatives: [],
    }
  },
  {
    en: {
      name: "HP",
      parentCompany: "HP",
      alternatives: [],
    },
    tr: {
      name: "HP",
      parentCompany: "HP",
      alternatives: [],
    },
    ar: {
      name: "HP",
      parentCompany: "HP",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nescafe",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Cafe Crown", "Kahve Dünyası", "Nevada", "Gloria Jeans"],
    },
    tr: {
      name: "Nescafe",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Cafe Crown", "Kahve Dünyası", "Nevada", "Gloria Jeans"],
    },
    ar: {
      name: "نيسكافيه",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Cafe Crown", "Kahve Dünyası", "Nevada", "Gloria Jeans"],
    }
  },
  {
    en: {
      name: "Nestle",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: []
    },
    tr: {
      name: "Nestle",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: []
    },
    ar: {
      name: "نستلة",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: []
    }
  },
  {
    en: {
      name: "Nesfit",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: []
    },
    tr: {
      name: "Nesfit",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: []
    },
    ar: {
      name: "نيسفيت",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: []
    }
  },
  {
    en: {
      name: "Maggi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Maggi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "ماجي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["منتجات العلالي"],
    }
  },
  {
    en: {
      name: "Kit Kat",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Kit Kat",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "كيت كات",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nido",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Nido",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "نيدو",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Alcon",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Alcon",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "Alcon",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Milo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Milo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "Milo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Crunch",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Crunch",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "Crunch",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dreyer's",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Dreyer's",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "درييرز",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nestlé Pure Life",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    tr: {
      name: "Nestlé Pure Life",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    ar: {
      name: "نيسليه بيور لايف",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    }
  },
  {
    en: {
      name: "Gerber",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Gerber",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "جيربير",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nespresso",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Nespresso",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "Nespresso",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Perrier",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Perrier",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "باريير",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Friskies",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Friskies",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "فريسكيس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fancy Feast",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Fancy Feast",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "فانسي فيست",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Lean Cuisine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Lean Cuisine",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "لين كويزين",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nesquik",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Nesquik",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "نيسكويك",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Purina One",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Purina One",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "بورينا ون",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hot Pockets",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Hot Pockets",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "هوت بوكيت",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Acqua Panna",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["المراعي"],
    },
    tr: {
      name: "Acqua Panna",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "أكوا بانا",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["المراعي"],
    }
  },
  {
    en: {
      name: "Aero",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Ülker"],
    },
    tr: {
      name: "Aero",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Ülker"],
    },
    ar: {
      name: "Aero",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Ülker"],
    }
  },
  {
    en: {
      name: "Quality Street",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Quality Street",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "كواليتي سترييت",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Stouffer's",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Stouffer's",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "ستوفيرس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nestea",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Nestea",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "نيستي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Häagen-Dazs",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Häagen-Dazs",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "هاجان دازس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "DiGiorno",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "DiGiorno",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "ديجيورنو",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Coffee-Mate",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Coffee-Mate",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "كوفيميت",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Carnation",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Carnation",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "كارنيشن",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Alpo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Alpo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "ألبو",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Garoto",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    tr: {
      name: "Garoto",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    },
    ar: {
      name: "جارتو",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Musketeers",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Musketeers",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "ماسكيترز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Abu Siouf",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Abu Siouf",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "Abu Siouf",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Acana",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Acana",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "Acana",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Altoids",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Altoids",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "Altoids",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Balisto",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Balisto",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "Balisto",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "5 Gum",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "5 Gum",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "5 Gum",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "3Musketeers",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "3Musketeers",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "3Musketeers",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "American Heritage",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "American Heritage",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "American Heritage",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ben's Original",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Ben's Original",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "بينس اوريجينال",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Celebrations",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Celebrations",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "سيليبريشن",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Twix",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Twix",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "تويكس",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cirku",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Cirku",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "سيركو",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "CocoaVia",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "CocoaVia",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "كوكا فيا",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Combos",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Combos",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "كومبوس",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dolmio",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Dolmio",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "دولميو",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dove Chocolate",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Dove Chocolate",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "دوف تشوكوليت",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ebly",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Ebly",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "إيبلي",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ethel M",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Ethel M",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "إيثيل إم",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "FLAVIA",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "FLAVIA",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "فلافيا",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fling",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Fling",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "فلينج",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Flyte",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Flyte",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "فلايت",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Forever Yours",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Forever Yours",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "فوريفر يورز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Galaxy",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Galaxy",
      parentCompany: "Mars Inc.",
      alternatives: ["Lavi kahve dünyası"],
    },
    ar: {
      name: "جالاكسي",
      parentCompany: "Mars Inc.",
      alternatives: ["Lavi kahve dünyası"],
    }
  },
  {
    en: {
      name: "Galaxy Bubbles",
      parentCompany: "Mars Inc.",
      alternatives: ["Lavi kahve dünyası"],
    },
    tr: {
      name: "Galaxy Bubbles",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "جالاكسي بابلز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Galaxy Minstrels",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Galaxy Minstrels",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "جالاكسي مينيستريلز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Goodness Knows",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Goodness Knows",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "جالاكسي نوز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "M-Azing",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "M-Azing",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "م-إيزنج",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "M&M's",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "M&M's",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "إم أند إمز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Maltesers",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Maltesers",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "مالتيسيرز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Marathon",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Marathon",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "ماراثون",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mars",
      parentCompany: "Mars Inc.",
      alternatives: ["metro"],
    },
    tr: {
      name: "Mars",
      parentCompany: "Mars Inc.",
      alternatives: ["metro"],
    },
    ar: {
      name: "مارس",
      parentCompany: "Mars Inc.",
      alternatives: ["metro"],
    }
  },
  {
    en: {
      name: "Masterfoods",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Masterfoods",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "ماسترفودز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Milky Way",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Milky Way",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "ميلكي واي",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Munch",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Munch",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "مانش",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Promite",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Promite",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "برومايت",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Revels",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Revels",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "ريفلز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Seeds of Change",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Seeds of Change",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "سييدس أوف تشينج",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Snickers",
      parentCompany: "Mars Inc.",
      alternatives: ["Canga"],
    },
    tr: {
      name: "Snickers",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "سنيكرز",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Topic",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Topic",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "توبيك",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tracker",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Tracker",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "تراكر",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Treets",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    tr: {
      name: "Treets",
      parentCompany: "Mars Inc.",
      alternatives: [],
    },
    ar: {
      name: "تريتس",
      parentCompany: "Mars Inc.",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Volvo ",
      parentCompany: "Volvo",
      alternatives: [],
    },
    tr: {
      name: "Volvo ",
      parentCompany: "Volvo",
      alternatives: [],
    },
    ar: {
      name: "فولفو ",
      parentCompany: "Volvo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Volvo Penta",
      parentCompany: "Volvo",
      alternatives: [],
    },
    tr: {
      name: "Volvo Penta",
      parentCompany: "Volvo",
      alternatives: [],
    },
    ar: {
      name: "فولفو بينتا",
      parentCompany: "Volvo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Rokbak",
      parentCompany: "Volvo",
      alternatives: [],
    },
    tr: {
      name: "Rokbak",
      parentCompany: "Volvo",
      alternatives: [],
    },
    ar: {
      name: "روكباك",
      parentCompany: "Volvo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Renault Trucks",
      parentCompany: "Volvo",
      alternatives: [],
    },
    tr: {
      name: "Renault Trucks",
      parentCompany: "Volvo",
      alternatives: [],
    },
    ar: {
      name: "رينولت تراكس",
      parentCompany: "Volvo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Prevost",
      parentCompany: "Volvo",
      alternatives: [],
    },
    tr: {
      name: "Prevost",
      parentCompany: "Volvo",
      alternatives: [],
    },
    ar: {
      name: "بريفوست",
      parentCompany: "Volvo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nova Bus",
      parentCompany: "Volvo",
      alternatives: [],
    },
    tr: {
      name: "Nova Bus",
      parentCompany: "Volvo",
      alternatives: [],
    },
    ar: {
      name: "نوفا باص",
      parentCompany: "Volvo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mack and Arquus",
      parentCompany: "Volvo",
      alternatives: [],
    },
    tr: {
      name: "Mack and Arquus",
      parentCompany: "Volvo",
      alternatives: [],
    },
    ar: {
      name: "ماك اند اركوس",
      parentCompany: "Volvo",
      alternatives: [],
    }
  },

  {
    en: {
      name: "Anchor",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Anchor",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Anchor",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "AsiaTrak",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "AsiaTrak",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "AsiaTrak",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "CAT",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "CAT",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "CAT",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cat Financial",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Cat Financial",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Cat Financial",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cat Lift Trucks",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Cat Lift Trucks",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Cat Lift Trucks",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cat Reman",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Cat Reman",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Cat Reman",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cat Rental Store",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Cat Rental Store",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Cat Rental Store",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "FG Wilson",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "FG Wilson",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "FG Wilson",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hindustan",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Hindustan",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Hindustan",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "MWM",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "MWM",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "MWM",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "MaK",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "MaK",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "MaK",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Perkins",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Perkins",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Perkins",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Progress Rail",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Progress Rail",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Progress Rail",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "SEM",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "SEM",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "SEM",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "SPM Oil & Gas",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "SPM Oil & Gas",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "SPM Oil & Gas",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Solar Turbines",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Solar Turbines",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Solar Turbines",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Turner Powertrain Systems",
      parentCompany: "CAT",
      alternatives: [],
    },
    tr: {
      name: "Turner Powertrain Systems",
      parentCompany: "CAT",
      alternatives: [],
    },
    ar: {
      name: "Turner Powertrain Systems",
      parentCompany: "CAT",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Siemens",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Siemens",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Siemens",
      parentCompany: "سيمينس",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Siemens Healthineers",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Siemens Healthineers",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Siemens Healthineers",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Siemens Energy",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Siemens Energy",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Siemens Energy",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Unify GmbH & Co. KG",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Unify GmbH & Co. KG",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Unify GmbH & Co. KG",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Siemens Mobility",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Siemens Mobility",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Siemens Mobility",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Siemens Financial Services",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Siemens Financial Services",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Siemens Financial Services",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dresser-Rand",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Dresser-Rand",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Dresser-Rand",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Siemens Building Technolo",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Siemens Building Technolo",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Siemens Building Technolo",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nokia Networks",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Nokia Networks",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Nokia Networks",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Primetals Technologies",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Primetals Technologies",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Primetals Technologies",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Siemens Plessey",
      parentCompany: "Siemens",
      alternatives: [],
    },
    tr: {
      name: "Siemens Plessey",
      parentCompany: "Siemens",
      alternatives: [],
    },
    ar: {
      name: "Siemens Plessey",
      parentCompany: "Siemens",
      alternatives: [],
    }
  },

  {
    en: {
      name: "Puma",
      parentCompany: "Puma",
      alternatives: [],
    },
    tr: {
      name: "Puma",
      parentCompany: "Puma",
      alternatives: [],
    },
    ar: {
      name: "بوما",
      parentCompany: "Puma",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cobra Golf ",
      parentCompany: "Puma",
      alternatives: [],
    },
    tr: {
      name: "Cobra Golf ",
      parentCompany: "Puma",
      alternatives: [],
    },
    ar: {
      name: "غوبرا غولف",
      parentCompany: "Puma",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Stichd",
      parentCompany: "Puma",
      alternatives: [],
    },
    tr: {
      name: "Stichd",
      parentCompany: "Puma",
      alternatives: [],
    },
    ar: {
      name: "Stichd",
      parentCompany: "Puma",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Carrefour",
      parentCompany: "Carrefour",
      alternatives: [],
    },
    tr: {
      name: "Carrefour",
      parentCompany: "Carrefour",
      alternatives: [],
    },
    ar: {
      name: "Carrefour",
      parentCompany: "Carrefour",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Chevron",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Chevron",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Chevron",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Standard Oil",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Standard Oil",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Standard Oil",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Texaco",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Texaco",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Texaco",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Caltex",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Caltex",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Caltex",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Unocal",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Unocal",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Unocal",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Star Mart",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Star Mart",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Star Mart",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Extra Mile",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Extra Mile",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Extra Mile",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Redwood Market",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Redwood Market",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Redwood Market",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Town Pantry",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Town Pantry",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Town Pantry",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Delo, Havoline",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Delo, Havoline",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Delo, Havoline",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Revtex",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Revtex",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Revtex",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ursa",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Ursa",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Ursa",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Techron",
      parentCompany: "Chevron",
      alternatives: [],
    },
    tr: {
      name: "Techron",
      parentCompany: "Chevron",
      alternatives: [],
    },
    ar: {
      name: "Techron",
      parentCompany: "Chevron",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hyundai",
      parentCompany: "Hyundai",
      alternatives: [],
    },
    tr: {
      name: "Hyundai",
      parentCompany: "Hyundai",
      alternatives: [],
    },
    ar: {
      name: "هيونداي",
      parentCompany: "Hyundai",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kia",
      parentCompany: "Hyundai",
      alternatives: [],
    },
    tr: {
      name: "Kia",
      parentCompany: "Hyundai",
      alternatives: [],
    },
    ar: {
      name: "كيا",
      parentCompany: "Hyundai",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Genesis",
      parentCompany: "Hyundai",
      alternatives: [],
    },
    tr: {
      name: "Genesis",
      parentCompany: "Hyundai",
      alternatives: [],
    },
    ar: {
      name: "Genesis",
      parentCompany: "Hyundai",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Osem",
      parentCompany: "Osem",
      alternatives: [],
    },
    tr: {
      name: "Osem",
      parentCompany: "Osem",
      alternatives: [],
    },
    ar: {
      name: "Osem",
      parentCompany: "Osem",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tivall",
      parentCompany: "Osem",
      alternatives: [],
    },
    tr: {
      name: "Tivall",
      parentCompany: "Osem",
      alternatives: [],
    },
    ar: {
      name: "Tivall",
      parentCompany: "Osem",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Pepsi",
      parentCompany: "PepsiCo",
      alternatives: [],
    },
    tr: {
      name: "Pepsi",
      parentCompany: "PepsiCo",
      alternatives: [],
    },
    ar: {
      name: "بيبسي",
      parentCompany: "PepsiCo",
      alternatives: ["kinza"],
    }
  },
  {
    en: {
      name: "Gatorade",
      parentCompany: "PepsiCo",
      alternatives: [],
    },
    tr: {
      name: "Gatorade",
      parentCompany: "PepsiCo",
      alternatives: [],
    },
    ar: {
      name: "Gatorade",
      parentCompany: "PepsiCo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Lays ",
      parentCompany: "PepsiCo",
      alternatives: ["Cipso", "Patito", "Amigo", "Patos", "Party"],
    },
    tr: {
      name: "Lays ",
      parentCompany: "PepsiCo",
      alternatives: ["Cipso", "Patito", "Amigo", "Patos", "Party"],
    },
    ar: {
      name: "ليز ",
      parentCompany: "PepsiCo",
      alternatives: ["Cipso", "Patito", "Amigo", "Patos", "Party"],
    }
  },
  {
    en: {
      name: "Lipton",
      parentCompany: "PepsiCo",
      alternatives: ["Çaykur", "mest"],
    },
    tr: {
      name: "Lipton",
      parentCompany: "PepsiCo",
      alternatives: ["Çaykur", "mest"],
    },
    ar: {
      name: "ليبتون",
      parentCompany: "PepsiCo",
      alternatives: ["Çaykur", "mest"],
    }
  },
  {
    en: {
      name: "Doritos ",
      parentCompany: "PepsiCo",
      alternatives: ["Cipso", "Patito", "Amigo", "Patos", "Party", "Chips master"],
    },
    tr: {
      name: "Doritos ",
      parentCompany: "PepsiCo",
      alternatives: ["Cipso", "Patito", "Amigo", "Patos", "Party", "Chips master"],
    },
    ar: {
      name: "دوريتوس ",
      parentCompany: "PepsiCo",
      alternatives: ["Cipso", "Patito", "Amigo", "Patos", "Party", "Chips master"],
    }
  },
  {
    en: {
      name: "Ruffles",
      parentCompany: "PepsiCo",
      alternatives: ["Chips master", "Cipso", "Patito", "Amigo", "Patos", "Party"],
    },
    tr: {
      name: "Ruffles",
      parentCompany: "PepsiCo",
      alternatives: ["Chips master", "Cipso", "Patito", "Amigo", "Patos", "Party"],
    },
    ar: {
      name: "رافلس",
      parentCompany: "PepsiCo",
      alternatives: ["Chips master", "Cipso", "Patito", "Amigo", "Patos", "Party"],
    }
  },
  {
    en: {
      name: "Yedigün ",
      parentCompany: "PepsiCo",
      alternatives: ["Uludağ Frutti Extra"],
    },
    tr: {
      name: "Yedigün ",
      parentCompany: "PepsiCo",
      alternatives: ["Uludağ Frutti Extra"],
    },
    ar: {
      name: "يدي جون ",
      parentCompany: "PepsiCo",
      alternatives: ["Uludağ Frutti Extra"],
    }
  },
  {
    en: {
      name: "Mirinda",
      parentCompany: "PepsiCo",
      alternatives: ["Uludağ Frutti Extra"],
    },
    tr: {
      name: "Mirinda",
      parentCompany: "PepsiCo",
      alternatives: ["Uludağ Frutti Extra"],
    },
    ar: {
      name: "ميريندا",
      parentCompany: "PepsiCo",
      alternatives: ["Uludağ Frutti Extra"],
    }
  },
  {
    en: {
      name: "Cheetos",
      parentCompany: "PepsiCo",
      alternatives: ["Cheetos"],
    },
    tr: {
      name: "Cheetos",
      parentCompany: "PepsiCo",
      alternatives: ["Cheetos"],
    },
    ar: {
      name: "تشيتوس",
      parentCompany: "PepsiCo",
      alternatives: ["Cheetos"],
    }
  },
  {
    en: {
      name: "Tropicana ",
      parentCompany: "PepsiCo",
      alternatives: ["Meysu","Dimes"],
    },
    tr: {
      name: "Tropicana ",
      parentCompany: "PepsiCo",
      alternatives: ["Meysu","Dimes"],
    },
    ar: {
      name: "تروبيكانا ",
      parentCompany: "PepsiCo",
      alternatives: ["Meysu","Dimes"],
    }
  },
  {
    en: {
      name: "Çerezza",
      parentCompany: "PepsiCo",
      alternatives: ["Patos"],
    },
    tr: {
      name: "Çerezza",
      parentCompany: "PepsiCo",
      alternatives: ["Patos"],
    },
    ar: {
      name: "تشيريزا",
      parentCompany: "PepsiCo",
      alternatives: ["Patos"],
    }
  },
  {
    en: {
      name: "Fruko gazoz ",
      parentCompany: "PepsiCo",
      alternatives: ["Niğde gazozu"],
    },
    tr: {
      name: "Fruko gazoz ",
      parentCompany: "PepsiCo",
      alternatives: ["Niğde gazozu"],
    },
    ar: {
      name: "فروكو ",
      parentCompany: "PepsiCo",
      alternatives: ["Niğde gazozu"],
    }
  },
  {
    en: {
      name: "7Up",
      parentCompany: "PepsiCo",
      alternatives: ["çamlıca"],
    },
    tr: {
      name: "7Up",
      parentCompany: "PepsiCo",
      alternatives: ["çamlıca"],
    },
    ar: {
      name: "سفن أب",
      parentCompany: "PepsiCo",
      alternatives: ["çamlıca"],
    }
  },
  {
    en: {
      name: "Rocco strip",
      parentCompany: "PepsiCo",
      alternatives: [],
    },
    tr: {
      name: "Rocco strip",
      parentCompany: "PepsiCo",
      alternatives: [],
    },
    ar: {
      name: "روكو ستريب",
      parentCompany: "PepsiCo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mountain Dew",
      parentCompany: "PepsiCo",
      alternatives: [],
    },
    tr: {
      name: "Mountain Dew",
      parentCompany: "PepsiCo",
      alternatives: [],
    },
    ar: {
      name: "ماونتن دو",
      parentCompany: "PepsiCo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "CAF ",
      parentCompany: "CAF",
      alternatives: [],
    },
    tr: {
      name: "CAF ",
      parentCompany: "CAF",
      alternatives: [],
    },
    ar: {
      name: "CAF ",
      parentCompany: "CAF",
      alternatives: [],
    }
  },
  {
    en: {
      name: "HyperBon",
      parentCompany: "BİM",
      alternatives: [],
    },
    tr: {
      name: "HyperBon",
      parentCompany: "BİM",
      alternatives: [],
    },
    ar: {
      name: "HyperBon",
      parentCompany: "BİM",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ülker",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    tr: {
      name: "Ülker",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    ar: {
      name: "Ülker",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Eti",
      parentCompany: "ETİ Gıda",
      alternatives: [],
    },
    tr: {
      name: "Eti",
      parentCompany: "ETİ Gıda",
      alternatives: [],
    },
    ar: {
      name: "Eti",
      parentCompany: "ETİ Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Pınar",
      parentCompany: "Pınar Süt Mamülleri",
      alternatives: [],
    },
    tr: {
      name: "Pınar",
      parentCompany: "Pınar Süt Mamülleri",
      alternatives: [],
    },
    ar: {
      name: "Pınar",
      parentCompany: "Pınar Süt Mamülleri",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tat",
      parentCompany: "Tat Gıda",
      alternatives: [],
    },
    tr: {
      name: "Tat",
      parentCompany: "Tat Gıda",
      alternatives: [],
    },
    ar: {
      name: "Tat",
      parentCompany: "Tat Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kerevitaş",
      parentCompany: "Kerevitaş Gıda",
      alternatives: [],
    },
    tr: {
      name: "Kerevitaş",
      parentCompany: "Kerevitaş Gıda",
      alternatives: [],
    },
    ar: {
      name: "Kerevitaş",
      parentCompany: "Kerevitaş Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sarelle",
      parentCompany: "Ülker",
      alternatives: [],
    },
    tr: {
      name: "Sarelle",
      parentCompany: "Ülker",
      alternatives: [],
    },
    ar: {
      name: "Sarelle",
      parentCompany: "Ülker",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cafe Crown",
      parentCompany: "Ülker",
      alternatives: [],
    },
    tr: {
      name: "Cafe Crown",
      parentCompany: "Ülker",
      alternatives: [],
    },
    ar: {
      name: "Cafe Crown",
      parentCompany: "Ülker",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Metro",
      parentCompany: "Ülker",
      alternatives: [],
    },
    tr: {
      name: "Metro",
      parentCompany: "Ülker",
      alternatives: [],
    },
    ar: {
      name: "ميترو",
      parentCompany: "Ülker",
      alternatives: [],
    }
  },
  {
    en: {
      name: "İçim",
      parentCompany: "AK Gıda",
      alternatives: [],
    },
    tr: {
      name: "İçim",
      parentCompany: "AK Gıda",
      alternatives: [],
    },
    ar: {
      name: "İçim",
      parentCompany: "AK Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dost",
      parentCompany: "AK Gıda",
      alternatives: [],
    },
    tr: {
      name: "Dost",
      parentCompany: "AK Gıda",
      alternatives: [],
    },
    ar: {
      name: "دوست",
      parentCompany: "AK Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "BİM",
      parentCompany: "BİM",
      alternatives: [],
    },
    tr: {
      name: "BİM",
      parentCompany: "BİM",
      alternatives: [],
    },
    ar: {
      name: "BİM",
      parentCompany: "BİM",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tadım",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    tr: {
      name: "Tadım",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    ar: {
      name: "Tadım",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mis",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    tr: {
      name: "Mis",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    ar: {
      name: "ميس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    }
  },
  {
    en: {
      name: "LC Waikiki",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.lcWaikiki,
      alternatives: [],
    },
    tr: {
      name: "LC Waikiki",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.lcWaikiki,
      alternatives: [],
    },
    ar: {
      name: "LC Waikiki",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.lcWaikiki,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Little Caesars",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.littleCaesars,
      alternatives: [],
    },
    tr: {
      name: "Little Caesars",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.littleCaesars,
      alternatives: [],
    },
    ar: {
      name: "Little Caesars",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.littleCaesars,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kiğılı",
      parentCompany: "Kiğılı Giyim",
      alternatives: [],
    },
    tr: {
      name: "Kiğılı",
      parentCompany: "Kiğılı Giyim",
      alternatives: [],
    },
    ar: {
      name: "Kiğılı",
      parentCompany: "Kiğılı Giyim",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hatemoğlu",
      parentCompany: "Hatemoğlu Tekstil",
      alternatives: [],
    },
    tr: {
      name: "Hatemoğlu",
      parentCompany: "Hatemoğlu Tekstil",
      alternatives: [],
    },
    ar: {
      name: "Hatemoğlu",
      parentCompany: "Hatemoğlu Tekstil",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Jakamen",
      parentCompany: "Jakamen Tekstil",
      alternatives: [],
    },
    tr: {
      name: "Jakamen",
      parentCompany: "Jakamen Tekstil",
      alternatives: [],
    },
    ar: {
      name: "Jakamen",
      parentCompany: "Jakamen Tekstil",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Damat",
      parentCompany: "Orka Holding",
      alternatives: [],
    },
    tr: {
      name: "Damat",
      parentCompany: "Orka Holding",
      alternatives: [],
    },
    ar: {
      name: "Damat",
      parentCompany: "Orka Holding",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tween",
      parentCompany: "Orka Holding",
      alternatives: [],
    },
    tr: {
      name: "Tween",
      parentCompany: "Orka Holding",
      alternatives: [],
    },
    ar: {
      name: "Tween",
      parentCompany: "Orka Holding",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Koton",
      parentCompany: "Koton Mağazacılık Tekstil",
      alternatives: [],
    },
    tr: {
      name: "Koton",
      parentCompany: "Koton Mağazacılık Tekstil",
      alternatives: [],
    },
    ar: {
      name: "Koton",
      parentCompany: "Koton Mağazacılık Tekstil",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Süvari",
      parentCompany: "Süvari Giyim",
      alternatives: [],
    },
    tr: {
      name: "Süvari",
      parentCompany: "Süvari Giyim",
      alternatives: [],
    },
    ar: {
      name: "Süvari",
      parentCompany: "Süvari Giyim",
      alternatives: [],
    }
  },
  {
    en: {
      name: "İmza",
      parentCompany: "İmza Mağazacılık",
      alternatives: [],
    },
    tr: {
      name: "İmza",
      parentCompany: "İmza Mağazacılık",
      alternatives: [],
    },
    ar: {
      name: "İmza",
      parentCompany: "İmza Mağazacılık",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Zeyrek",
      parentCompany: "Zeyrek Tekstil",
      alternatives: [],
    },
    tr: {
      name: "Zeyrek",
      parentCompany: "Zeyrek Tekstil",
      alternatives: [],
    },
    ar: {
      name: "Zeyrek",
      parentCompany: "Zeyrek Tekstil",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kinetix",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    tr: {
      name: "Kinetix",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    ar: {
      name: "Kinetix",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Gezer",
      parentCompany: "Gezer Ayakkabı",
      alternatives: [],
    },
    tr: {
      name: "Gezer",
      parentCompany: "Gezer Ayakkabı",
      alternatives: [],
    },
    ar: {
      name: "Gezer",
      parentCompany: "Gezer Ayakkabı",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Scooter",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    tr: {
      name: "Scooter",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    ar: {
      name: "Scooter",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Polaris",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    tr: {
      name: "Polaris",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    ar: {
      name: "Polaris",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Aydi",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    tr: {
      name: "Aydi",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    ar: {
      name: "Aydi",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    }
  },
  {
    en: {
      name: "King Paulo",
      parentCompany: "King Paulo Tekstil",
      alternatives: [],
    },
    tr: {
      name: "King Paulo",
      parentCompany: "King Paulo Tekstil",
      alternatives: [],
    },
    ar: {
      name: "King Paulo",
      parentCompany: "King Paulo Tekstil",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Forelli",
      parentCompany: "Forelli Tekstil",
      alternatives: [],
    },
    tr: {
      name: "Forelli",
      parentCompany: "Forelli Tekstil",
      alternatives: [],
    },
    ar: {
      name: "Forelli",
      parentCompany: "Forelli Tekstil",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Lescon",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    tr: {
      name: "Lescon",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    },
    ar: {
      name: "Lescon",
      parentCompany: "Eroğlu Holding",
      alternatives: [],
    }
  },
  {
    en: {
      name: "YDS Ayakkabı",
      parentCompany: "YDS Ayakkabı",
      alternatives: [],
    },
    tr: {
      name: "YDS Ayakkabı",
      parentCompany: "YDS Ayakkabı",
      alternatives: [],
    },
    ar: {
      name: "YDS Ayakkabı",
      parentCompany: "YDS Ayakkabı",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Torku",
      parentCompany: "Birleşik Holding",
      alternatives: [],
    },
    tr: {
      name: "Torku",
      parentCompany: "Birleşik Holding",
      alternatives: [],
    },
    ar: {
      name: "Torku",
      parentCompany: "Birleşik Holding",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Afia Gıda",
      parentCompany: "Afia Gıda",
      alternatives: [],
    },
    tr: {
      name: "Afia Gıda",
      parentCompany: "Afia Gıda",
      alternatives: [],
    },
    ar: {
      name: "Afia Gıda",
      parentCompany: "Afia Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Şölen",
      parentCompany: "Şölen Çikolata Gıda",
      alternatives: [],
    },
    tr: {
      name: "Şölen",
      parentCompany: "Şölen Çikolata Gıda",
      alternatives: [],
    },
    ar: {
      name: "Şölen",
      parentCompany: "Şölen Çikolata Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Eriş",
      parentCompany: "Erişler Gıda",
      alternatives: [],
    },
    tr: {
      name: "Eriş",
      parentCompany: "Erişler Gıda",
      alternatives: [],
    },
    ar: {
      name: "Eriş",
      parentCompany: "Erişler Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kristal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kervanGida,
      alternatives: [],
    },
    tr: {
      name: "Kristal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kervanGida,
      alternatives: [],
    },
    ar: {
      name: "Kristal",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kervanGida,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Alpedo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kervanGida,
      alternatives: [],
    },
    tr: {
      name: "Alpedo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kervanGida,
      alternatives: [],
    },
    ar: {
      name: "Alpedo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kervanGida,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Vip Kahve",
      parentCompany: "Vip Gıda",
      alternatives: [],
    },
    tr: {
      name: "Vip Kahve",
      parentCompany: "Vip Gıda",
      alternatives: [],
    },
    ar: {
      name: "Vip Kahve",
      parentCompany: "Vip Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sek",
      parentCompany: "Dana Dış Ticaret",
      alternatives: [],
    },
    tr: {
      name: "Sek",
      parentCompany: "Dana Dış Ticaret",
      alternatives: [],
    },
    ar: {
      name: "Sek",
      parentCompany: "Dana Dış Ticaret",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Pehlivan Helva",
      parentCompany: "Kervan Gıda",
      alternatives: [],
    },
    tr: {
      name: "Pehlivan Helva",
      parentCompany: "Kervan Gıda",
      alternatives: [],
    },
    ar: {
      name: "Pehlivan Helva",
      parentCompany: "Kervan Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Burcu Salça",
      parentCompany: "Burcu Gıda",
      alternatives: [],
    },
    tr: {
      name: "Burcu Salça",
      parentCompany: "Burcu Gıda",
      alternatives: [],
    },
    ar: {
      name: "Burcu Salça",
      parentCompany: "Burcu Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Selva",
      parentCompany: "Selva Gıda",
      alternatives: [],
    },
    tr: {
      name: "Selva",
      parentCompany: "Selva Gıda",
      alternatives: [],
    },
    ar: {
      name: "Selva",
      parentCompany: "Selva Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Aytaç",
      parentCompany: "Aytaç Gıda",
      alternatives: [],
    },
    tr: {
      name: "Aytaç",
      parentCompany: "Aytaç Gıda",
      alternatives: [],
    },
    ar: {
      name: "Aytaç",
      parentCompany: "Aytaç Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kevser",
      parentCompany: "Kevser Gıda",
      alternatives: [],
    },
    tr: {
      name: "Kevser",
      parentCompany: "Kevser Gıda",
      alternatives: [],
    },
    ar: {
      name: "Kevser",
      parentCompany: "Kevser Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tariş",
      parentCompany: "Tariş Zeytin",
      alternatives: [],
    },
    tr: {
      name: "Tariş",
      parentCompany: "Tariş Zeytin",
      alternatives: [],
    },
    ar: {
      name: "Tariş",
      parentCompany: "Tariş Zeytin",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Zade",
      parentCompany: "Zade Vital Gıda",
      alternatives: [],
    },
    tr: {
      name: "Zade",
      parentCompany: "Zade Vital Gıda",
      alternatives: [],
    },
    ar: {
      name: "Zade",
      parentCompany: "Zade Vital Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Oruçoğlu",
      parentCompany: "Oruçoğlu Yağ",
      alternatives: [],
    },
    tr: {
      name: "Oruçoğlu",
      parentCompany: "Oruçoğlu Yağ",
      alternatives: [],
    },
    ar: {
      name: "Oruçoğlu",
      parentCompany: "Oruçoğlu Yağ",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Uludağ",
      parentCompany: "Uludağ",
      alternatives: [],
    },
    tr: {
      name: "Uludağ",
      parentCompany: "Uludağ",
      alternatives: [],
    },
    ar: {
      name: "Uludağ",
      parentCompany: "Uludağ",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hamidiye Su",
      parentCompany: "Hamidiye Su",
      alternatives: [],
    },
    tr: {
      name: "Hamidiye Su",
      parentCompany: "Hamidiye Su",
      alternatives: [],
    },
    ar: {
      name: "Hamidiye Su",
      parentCompany: "Hamidiye Su",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kayışdağı Su",
      parentCompany: "Kayışdağı Su",
      alternatives: [],
    },
    tr: {
      name: "Kayışdağı Su",
      parentCompany: "Kayışdağı Su",
      alternatives: [],
    },
    ar: {
      name: "Kayışdağı Su",
      parentCompany: "Kayışdağı Su",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Aytaç su",
      parentCompany: "Aytaç su",
      alternatives: [],
    },
    tr: {
      name: "Aytaç su",
      parentCompany: "Aytaç su",
      alternatives: [],
    },
    ar: {
      name: "Aytaç su",
      parentCompany: "Aytaç su",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Niğde Gazozu",
      parentCompany: "Niğde Gazozu",
      alternatives: [],
    },
    tr: {
      name: "Niğde Gazozu",
      parentCompany: "Niğde Gazozu",
      alternatives: [],
    },
    ar: {
      name: "Niğde Gazozu",
      parentCompany: "Niğde Gazozu",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Beypazarı Maden Suyu",
      parentCompany: "Beypazarı Maden Suyu",
      alternatives: [],
    },
    tr: {
      name: "Beypazarı Maden Suyu",
      parentCompany: "Beypazarı Maden Suyu",
      alternatives: [],
    },
    ar: {
      name: "Beypazarı Maden Suyu",
      parentCompany: "Beypazarı Maden Suyu",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kızılay",
      parentCompany: "Kızılay",
      alternatives: [],
    },
    tr: {
      name: "Kızılay",
      parentCompany: "Kızılay",
      alternatives: [],
    },
    ar: {
      name: "Kızılay",
      parentCompany: "Kızılay",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Doğanay",
      parentCompany: "Doğanay",
      alternatives: [],
    },
    tr: {
      name: "Doğanay",
      parentCompany: "Doğanay",
      alternatives: [],
    },
    ar: {
      name: "Doğanay",
      parentCompany: "Doğanay",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nazo",
      parentCompany: "Nazo",
      alternatives: [],
    },
    tr: {
      name: "Nazo",
      parentCompany: "Nazo",
      alternatives: [],
    },
    ar: {
      name: "Nazo",
      parentCompany: "Nazo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Aroma",
      parentCompany: "Aroma",
      alternatives: [],
    },
    tr: {
      name: "Aroma",
      parentCompany: "Aroma",
      alternatives: [],
    },
    ar: {
      name: "Aroma",
      parentCompany: "Aroma",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Meyöz",
      parentCompany: "Meyöz",
      alternatives: [],
    },
    tr: {
      name: "Meyöz",
      parentCompany: "Meyöz",
      alternatives: [],
    },
    ar: {
      name: "Meyöz",
      parentCompany: "Meyöz",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hünkar Gazoz",
      parentCompany: "Hünkar Gazoz",
      alternatives: [],
    },
    tr: {
      name: "Hünkar Gazoz",
      parentCompany: "Hünkar Gazoz",
      alternatives: [],
    },
    ar: {
      name: "Hünkar Gazoz",
      parentCompany: "Hünkar Gazoz",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Evyap",
      parentCompany: "Evyap",
      alternatives: [],
    },
    tr: {
      name: "Evyap",
      parentCompany: "Evyap",
      alternatives: [],
    },
    ar: {
      name: "Evyap",
      parentCompany: "Evyap",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Naturalive",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    tr: {
      name: "Naturalive",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    ar: {
      name: "Naturalive",
      parentCompany: "Naturalive",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Huma Liva",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    tr: {
      name: "Huma Liva",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    ar: {
      name: "Huma Liva",
      parentCompany: "Naturalive",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Livack",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    tr: {
      name: "Livack",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    ar: {
      name: "Livack",
      parentCompany: "Naturalive",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Natura Baby",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    tr: {
      name: "Natura Baby",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    ar: {
      name: "Natura Baby",
      parentCompany: "Naturalive",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Naturalive Beauty",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    tr: {
      name: "Naturalive Beauty",
      parentCompany: "Naturalive",
      alternatives: [],
    },
    ar: {
      name: "Naturalive Beauty",
      parentCompany: "Naturalive",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Duru",
      parentCompany: "Duru",
      alternatives: [],
    },
    tr: {
      name: "Duru",
      parentCompany: "Duru",
      alternatives: [],
    },
    ar: {
      name: "Duru",
      parentCompany: "Duru",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bingo",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Bingo",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "Bingo",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Molped",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Molped",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "Molped",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Molfix",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Molfix",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "مولفيكس",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Familia",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Familia",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "Familia",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "HAS",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "HAS",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "HAS",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "joly",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "joly",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "joly",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "molped",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "molped",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "molped",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Papia",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Papia",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "Papia",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Teno",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Teno",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "Teno",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Test",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Test",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "Test",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bebem",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Bebem",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "Bebem",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Envoy",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Envoy",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "Envoy",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Focus",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    tr: {
      name: "Focus",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    },
    ar: {
      name: "Focus",
      parentCompany: "Hayat Kimya",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mintax",
      parentCompany: "Dürüst Kimya Sanayi",
      alternatives: [],
    },
    tr: {
      name: "Mintax",
      parentCompany: "Dürüst Kimya Sanayi",
      alternatives: [],
    },
    ar: {
      name: "Mintax",
      parentCompany: "Dürüst Kimya Sanayi",
      alternatives: [],
    }
  },
  {
    en: {
      name: "ABC",
      parentCompany: "ABC Deterjan Sanayi",
      alternatives: [],
    },
    tr: {
      name: "ABC",
      parentCompany: "ABC Deterjan Sanayi",
      alternatives: [],
    },
    ar: {
      name: "ABC",
      parentCompany: "ABC Deterjan Sanayi",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Peros",
      parentCompany: "Peros",
      alternatives: [],
    },
    tr: {
      name: "Peros",
      parentCompany: "Peros",
      alternatives: [],
    },
    ar: {
      name: "Peros",
      parentCompany: "Peros",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ersağ",
      parentCompany: "Ersağ",
      alternatives: [],
    },
    tr: {
      name: "Ersağ",
      parentCompany: "Ersağ",
      alternatives: [],
    },
    ar: {
      name: "Ersağ",
      parentCompany: "Ersağ",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Viking",
      parentCompany: "Viking",
      alternatives: [],
    },
    tr: {
      name: "Viking",
      parentCompany: "Viking",
      alternatives: [],
    },
    ar: {
      name: "Viking",
      parentCompany: "Viking",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Flaminfo",
      parentCompany: "Flaminfo",
      alternatives: [],
    },
    tr: {
      name: "Flaminfo",
      parentCompany: "Flaminfo",
      alternatives: [],
    },
    ar: {
      name: "Flaminfo",
      parentCompany: "Flaminfo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mio",
      parentCompany: "Mio",
      alternatives: [],
    },
    tr: {
      name: "Mio",
      parentCompany: "Mio",
      alternatives: [],
    },
    ar: {
      name: "Mio",
      parentCompany: "Mio",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hes",
      parentCompany: "Hes",
      alternatives: [],
    },
    tr: {
      name: "Hes",
      parentCompany: "Hes",
      alternatives: [],
    },
    ar: {
      name: "Hes",
      parentCompany: "Hes",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Paksoy",
      parentCompany: "Paksoy",
      alternatives: [],
    },
    tr: {
      name: "Paksoy",
      parentCompany: "Paksoy",
      alternatives: [],
    },
    ar: {
      name: "Paksoy",
      parentCompany: "Paksoy",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Eti Matik",
      parentCompany: "Eti Matik",
      alternatives: [],
    },
    tr: {
      name: "Eti Matik",
      parentCompany: "Eti Matik",
      alternatives: [],
    },
    ar: {
      name: "Eti Matik",
      parentCompany: "Eti Matik",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Telekom",
      parentCompany: "Telekom",
      alternatives: [],
    },
    tr: {
      name: "Telekom",
      parentCompany: "Telekom",
      alternatives: [],
    },
    ar: {
      name: "Telekom",
      parentCompany: "Telekom",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Vestel",
      parentCompany: "Vestel",
      alternatives: [],
    },
    tr: {
      name: "Vestel",
      parentCompany: "Vestel",
      alternatives: [],
    },
    ar: {
      name: "Vestel",
      parentCompany: "Vestel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Casper",
      parentCompany: "Casper",
      alternatives: [],
    },
    tr: {
      name: "Casper",
      parentCompany: "Casper",
      alternatives: [],
    },
    ar: {
      name: "Casper",
      parentCompany: "Casper",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bip",
      parentCompany: "Bip",
      alternatives: [],
    },
    tr: {
      name: "Bip",
      parentCompany: "Bip",
      alternatives: [],
    },
    ar: {
      name: "Bip",
      parentCompany: "Bip",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bimeks",
      parentCompany: "Bimeks",
      alternatives: [],
    },
    tr: {
      name: "Bimeks",
      parentCompany: "Bimeks",
      alternatives: [],
    },
    ar: {
      name: "Bimeks",
      parentCompany: "Bimeks",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Vatan Bilgisayar",
      parentCompany: "Vatan Bilgisayar",
      alternatives: [],
    },
    tr: {
      name: "Vatan Bilgisayar",
      parentCompany: "Vatan Bilgisayar",
      alternatives: [],
    },
    ar: {
      name: "Vatan Bilgisayar",
      parentCompany: "Vatan Bilgisayar",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kaan Telefon",
      parentCompany: "Kaan Telefon",
      alternatives: [],
    },
    tr: {
      name: "Kaan Telefon",
      parentCompany: "Kaan Telefon",
      alternatives: [],
    },
    ar: {
      name: "Kaan Telefon",
      parentCompany: "Kaan Telefon",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Arçelik",
      parentCompany: "Arçelik",
      alternatives: [],
    },
    tr: {
      name: "Arçelik",
      parentCompany: "Arçelik",
      alternatives: [],
    },
    ar: {
      name: "Arçelik",
      parentCompany: "Arçelik",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Beko",
      parentCompany: "Beko",
      alternatives: [],
    },
    tr: {
      name: "Beko",
      parentCompany: "Beko",
      alternatives: [],
    },
    ar: {
      name: "Beko",
      parentCompany: "Beko",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Arzum",
      parentCompany: "Arzum",
      alternatives: [],
    },
    tr: {
      name: "Arzum",
      parentCompany: "Arzum",
      alternatives: [],
    },
    ar: {
      name: "Arzum",
      parentCompany: "Arzum",
      alternatives: [],
    }
  },
  {
    en: {
      name: "King",
      parentCompany: "King",
      alternatives: [],
    },
    tr: {
      name: "King",
      parentCompany: "King",
      alternatives: [],
    },
    ar: {
      name: "King",
      parentCompany: "King",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sinbo",
      parentCompany: "Sinbo",
      alternatives: [],
    },
    tr: {
      name: "Sinbo",
      parentCompany: "Sinbo",
      alternatives: [],
    },
    ar: {
      name: "Sinbo",
      parentCompany: "Sinbo",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Gold Master",
      parentCompany: "Gold Master",
      alternatives: [],
    },
    tr: {
      name: "Gold Master",
      parentCompany: "Gold Master",
      alternatives: [],
    },
    ar: {
      name: "Gold Master",
      parentCompany: "Gold Master",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tukaş",
      parentCompany: "Tukaş",
      alternatives: [],
    },
    tr: {
      name: "Tukaş",
      parentCompany: "Tukaş",
      alternatives: [],
    },
    ar: {
      name: "Tukaş",
      parentCompany: "Tukaş",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sütaş",
      parentCompany: "Sütaş",
      alternatives: [],
    },
    tr: {
      name: "Sütaş",
      parentCompany: "Sütaş",
      alternatives: [],
    },
    ar: {
      name: "Sütaş",
      parentCompany: "Sütaş",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Yayla",
      parentCompany: "Yayla Agro Gida",
      alternatives: [],
    },
    tr: {
      name: "Yayla",
      parentCompany: "Yayla Agro Gida",
      alternatives: [],
    },
    ar: {
      name: "Yayla",
      parentCompany: "Yayla Agro Gida",
      alternatives: [],
    }
  },
  {
    en: {
      name: "superfresh",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    tr: {
      name: "superfresh",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    },
    ar: {
      name: "superfresh",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.yildizHolding,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kervan",
      parentCompany: "Kervan Gıda",
      alternatives: [],
    },
    tr: {
      name: "Kervan",
      parentCompany: "Kervan Gıda",
      alternatives: [],
    },
    ar: {
      name: "Kervan",
      parentCompany: "Kervan Gıda",
      alternatives: [],
    }
  },
  {
    en: {
      name: "First",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["oneo (ülker)"],
    },
    tr: {
      name: "First",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["oneo (ülker)"],
    },
    ar: {
      name: "فيرست",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["oneo (ülker)"],
    }
  },
  {
    en: {
      name: "Falım",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["yıldız (ülker)"],
    },
    tr: {
      name: "Falım",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["yıldız (ülker)"],
    },
    ar: {
      name: "فالم",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["yıldız (ülker)"],
    }
  },
  {
    en: {
      name: "Şıpsevdi",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    tr: {
      name: "Şıpsevdi",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    ar: {
      name: "شيبسيفدي",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Barni",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    tr: {
      name: "Barni",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    ar: {
      name: "Barni",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    }
  },
  {
    en: {
      name: "7Days",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["Un", "Torku"],
    },
    tr: {
      name: "7Days",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["Un", "Torku"],
    },
    ar: {
      name: "7Days",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["Un", "Torku"],
    }
  },
  {
    en: {
      name: "5 Star",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    tr: {
      name: "5 Star",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    ar: {
      name: "5 Star",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Jelibon",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["yupo (ülker)"],
    },
    tr: {
      name: "Jelibon",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["yupo (ülker)"],
    },
    ar: {
      name: "جيليبون",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["yupo (ülker)"],
    }
  },
  {
    en: {
      name: "Topitop",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    tr: {
      name: "Topitop",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    ar: {
      name: "توبتيبو",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Olips",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["bonbon (ülker)"],
    },
    tr: {
      name: "Olips",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["bonbon (ülker)"],
    },
    ar: {
      name: "أولبس",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["bonbon (ülker)"],
    }
  },
  {
    en: {
      name: "Missbon",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    tr: {
      name: "Missbon",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    ar: {
      name: "ميسبون",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tofita",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    tr: {
      name: "Tofita",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    ar: {
      name: "توفيتا",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kent",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    tr: {
      name: "Kent",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    ar: {
      name: "كينت",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Milka",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["buonu (ülker)","çikolata barları (eti)"],
    },
    tr: {
      name: "Milka",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["buonu (ülker)","çikolata barları (eti)"],
    },
    ar: {
      name: "ميلكا",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["buonu (ülker)","çikolata barları (eti)"],
    }
  },
  {
    en: {
      name: "Toblerone",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    tr: {
      name: "Toblerone",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    },
    ar: {
      name: "توبليرون",
      parentCompany: "Mondelez International Holdings",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Oreo",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["eti","nero"],
    },
    tr: {
      name: "Oreo",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["eti","nero"],
    },
    ar: {
      name: "أوريو",
      parentCompany: "Mondelez International Holdings",
      alternatives: ["eti","nero"],
    }
  },
  {
    en: {
      name: "Axe",
      parentCompany: "Unilever",
      alternatives: ["Siveno"]
    },
    tr: {
      name: "Axe",
      parentCompany: "Unilever",
      alternatives: ["Siveno"]
    },
    ar: {
      name: "Axe",
      parentCompany: "Unilever",
      alternatives: ["Siveno"]
    }
  },
  {
    en: {
      name: "Clear",
      parentCompany: "Unilever",
      alternatives: ["Naturalive", "Bioxcin"]
    },
    tr: {
      name: "Clear",
      parentCompany: "Unilever",
      alternatives: ["Naturalive", "Bioxcin"]
    },
    ar: {
      name: "كلير",
      parentCompany: "Unilever",
      alternatives: ["Naturalive", "Bioxcin"]
    }
  },
  {
    en: {
      name: "Bango",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Bango",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Bango",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ben & Jerry's",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Ben & Jerry's",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Ben & Jerry's",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cif",
      parentCompany: "Unilever",
      alternatives: ["Bingo"],
    },
    tr: {
      name: "Cif",
      parentCompany: "Unilever",
      alternatives: ["Bingo"],
    },
    ar: {
      name: "جيف",
      parentCompany: "Unilever",
      alternatives: ['Bingo'],
    }
  },
  {
    en: {
      name: "Jif",
      parentCompany: "Unilever",
      alternatives: ["Bingo"],
    },
    tr: {
      name: "Jif",
      parentCompany: "Unilever",
      alternatives: ["Bingo"],
    },
    ar: {
      name: "Jif",
      parentCompany: "Unilever",
      alternatives: ['Bingo'],
    }
  },
  {
    en: {
      name: "Yumoş",
      parentCompany: "Unilever",
      alternatives: ["Bingo"],
    },
    tr: {
      name: "Yumoş",
      parentCompany: "Unilever",
      alternatives: ["Bingo"],
    },
    ar: {
      name: "يوموش",
      parentCompany: "Unilever",
      alternatives: ['Bingo'],
    }
  },
  {
    en: {
      name: "Vim",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Vim",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "فيم",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sunlight",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Sunlight",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "صن لايت",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Rinso",
      parentCompany: "Unilever",
      alternatives: ["Bingo"],
    },
    tr: {
      name: "Rinso",
      parentCompany: "Unilever",
      alternatives: ["Bingo"],
    },
    ar: {
      name: "رينسو",
      parentCompany: "Unilever",
      alternatives: ['Bingo'],
    }
  },
  {
    en: {
      name: "Pure Line",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Pure Line",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "بيور لاين",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Comfort",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Comfort",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Comfort",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Surf",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Surf",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "سيرف",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Domestos",
      parentCompany: "Unilever",
      alternatives: ["ultra","yogun","ACE"]
    },
    tr: {
      name: "Domestos",
      parentCompany: "Unilever",
      alternatives: ["ultra","yogun","ACE"]
    },
    ar: {
      name: "Domestos",
      parentCompany: "Unilever",
      alternatives: ["ultra","yogun","ACE"]
    }
  },
  {
    en: {
      name: "Dove",
      parentCompany: "Unilever",
      alternatives: ["Saloon"]
    },
    tr: {
      name: "Dove",
      parentCompany: "Unilever",
      alternatives: ["Saloon"]
    },
    ar: {
      name: "دوف",
      parentCompany: "Unilever",
      alternatives: ["Saloon"]
    }
  },
  {
    en: {
      name: "Equilibra",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Equilibra",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Equilibra",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hellmann's",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Hellmann's",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Hellmann's",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Knorr",
      parentCompany: "Unilever",
      alternatives: ["Knorr"],
    },
    tr: {
      name: "Knorr",
      parentCompany: "Unilever",
      alternatives: ["Knorr"],
    },
    ar: {
      name: "كنور",
      parentCompany: "Unilever",
      alternatives: ["Knorr"],
    }
  },
  {
    en: {
      name: "Signal",
      parentCompany: "Unilever",
      alternatives: ["Experdent","EyüpSabriTunce"],
    },
    tr: {
      name: "Signal",
      parentCompany: "Unilever",
      alternatives: ["Experdent","EyüpSabriTunce"],
    },
    ar: {
      name: "سيجنال",
      parentCompany: "Unilever",
      alternatives: ["Experdent","EyüpSabriTunce","Dabur Herb'I"],
    }
  },
  {
    en: {
      name: "LUX",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "LUX",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "LUX",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Lifebuoy",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Lifebuoy",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Lifebuoy",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Liquid I.V.",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Liquid I.V.",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Liquid I.V.",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Love Beauty & Planet",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Love Beauty & Planet",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Love Beauty & Planet",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Magnum",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Magnum",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "ماجنوم",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "OLLY",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "OLLY",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "OLLY",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "OMO",
      parentCompany: "Unilever",
      alternatives: ["Bingo","ABC"],
    },
    tr: {
      name: "OMO",
      parentCompany: "Unilever",
      alternatives: ["Bingo ","ABC"],
    },
    ar: {
      name: "اومو",
      parentCompany: "Unilever",
      alternatives: ["Bingo ","ABC"],
    }
  },
  {
    en: {
      name: "Onnit",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Onnit",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Onnit",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Rexona",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Rexona",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Rexona",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Seventh Generation",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Seventh Generation",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Seventh Generation",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "SmartyPants",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "SmartyPants",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "SmartyPants",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sunsilk",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Sunsilk",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Sunsilk",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "The Vegetarian Butcher",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "The Vegetarian Butcher",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "The Vegetarian Butcher",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Vaseline",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Vaseline",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "فازلين",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Wall's",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Wall's",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Wall's",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },

  {
    en: {
      name: "Cornetto",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Cornetto",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "كورنيت",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Algida",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Algida",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "ألجيدا",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Calve",
      parentCompany: "Unilever",
      alternatives: ["Burcu"],
    },
    tr: {
      name: "Calve",
      parentCompany: "Unilever",
      alternatives: ["Burcu"],
    },
    ar: {
      name: "كالفي",
      parentCompany: "Unilever",
      alternatives: ["Burcu"],
    }
  },
  {
    en: {
      name: "Carte D'Or",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Carte D'Or",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "كارتي دور",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Max",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Max",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "ماكس",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Good Humor",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Good Humor",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "جود هيومر",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "TRESemme",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "TRESemme",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "تريسيم",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Pepsodent",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Pepsodent",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "بيبسودينت",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Close Up",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Close Up",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "كلوز أب",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Simple",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Simple",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Simple",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Toni&Guy",
      parentCompany: "Unilever",
      alternatives: [],
    },
    tr: {
      name: "Toni&Guy",
      parentCompany: "Unilever",
      alternatives: [],
    },
    ar: {
      name: "Toni&Guy",
      parentCompany: "Unilever",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Elidor",
      parentCompany: "Unilever",
      alternatives: ["BioPlas", "Duru"],
    },
    tr: {
      name: "Elidor",
      parentCompany: "Unilever",
      alternatives: ["BioPlas", "Duru"],
    },
    ar: {
      name: "اليدور",
      parentCompany: "Unilever",
      alternatives: ["BioPlas", "Duru"],
    }
  },
  {
    en: {
      name: "Cerave",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Cerave",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Cerave",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Luxury",
      parentCompany: "L'Oréal",
      alternatives: ["Duru"],
    },
    tr: {
      name: "Luxury",
      parentCompany: "L'Oréal",
      alternatives: ["Duru"],
    },
    ar: {
      name: "Luxury",
      parentCompany: "L'Oréal",
      alternatives: ["Duru"],
    }
  },
  {
    en: {
      name: "L’Oréal Paris",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "L’Oréal Paris",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "L’Oréal Paris",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Le Club des Créateurs",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Le Club des Créateurs",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Le Club des Créateurs",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Maybelline",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Maybelline",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Maybelline",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "SoftSheen-Carson",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "SoftSheen-Carson",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "SoftSheen-Carson",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "L’Oréal Professional",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "L’Oréal Professional",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "L’Oréal Professional",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kerastase",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Kerastase",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Kerastase",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Redken",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Redken",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Redken",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Matrix",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Matrix",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Matrix",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mizano",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Mizano",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Mizano",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Shu Uemura Art of Hair",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Shu Uemura Art of Hair",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Shu Uemura Art of Hair",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Keraskin Esthetics",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Keraskin Esthetics",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Keraskin Esthetics",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Lancôme",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Lancôme",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Lancôme",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Biotherm",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Biotherm",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Biotherm",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Helena Rubenstein",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Helena Rubenstein",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Helena Rubenstein",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kiehl’s",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Kiehl’s",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Kiehl’s",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Shu Uemura",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Shu Uemura",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Shu Uemura",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Giorgio Armani",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Giorgio Armani",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Giorgio Armani",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ralph Lauren",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Ralph Lauren",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Ralph Lauren",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cacharel",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Cacharel",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Cacharel",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Viktor & Rolf, Diesel",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Viktor & Rolf, Diesel",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Viktor & Rolf, Diesel",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "YSL Beauté",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "YSL Beauté",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "YSL Beauté",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Vichy",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Vichy",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Vichy",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "LaRoche-Posay",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "LaRoche-Posay",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "LaRoche-Posay",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Innerve",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Innerve",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Innerve",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "SkinCeuticals",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "SkinCeuticals",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "SkinCeuticals",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Takami",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Takami",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Takami",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Garnier Fructis",
      parentCompany: "L'Oréal",
      alternatives: ["Beeo","Emotion"],
    },
    tr: {
      name: "Garnier Fructis",
      parentCompany: "L'Oréal",
      alternatives: ["Beeo","Emotion"],
    },
    ar: {
      name: "غارنييه",
      parentCompany: "L'Oréal",
      alternatives: ["Beeo","Emotion"],
    }
  },
  {
    en: {
      name: "Prada",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Prada",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Prada",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "My Way by Giorgio Armani",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "My Way by Giorgio Armani",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "My Way by Giorgio Armani",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Voce Viva by Valentino",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Voce Viva by Valentino",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Voce Viva by Valentino",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kérastase",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Kérastase",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Kérastase",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Redken Volume Maximizer",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Redken Volume Maximizer",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Redken Volume Maximizer",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Liftactiv Supreme",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Liftactiv Supreme",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Liftactiv Supreme",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fructis Pure",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Fructis Pure",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Fructis Pure",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Garnier",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    tr: {
      name: "Garnier",
      parentCompany: "L'Oréal",
      alternatives: [],
    },
    ar: {
      name: "Garnier",
      parentCompany: "L'Oréal",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Loreal",
      parentCompany: "L'Oréal",
      alternatives: ["Nars","Hudabeauty"],
    },
    tr: {
      name: "Loreal",
      parentCompany: "L'Oréal",
      alternatives: ["Nars","Hudabeauty"],
    },
    ar: {
      name: "لوريال",
      parentCompany: "L'Oréal",
      alternatives: ["Nars","Hudabeauty"],
    }
  },
  {
    en: {
      name: "Motorola",
      parentCompany: "Motorola",
      alternatives: [],
    },
    tr: {
      name: "Motorola",
      parentCompany: "Motorola",
      alternatives: [],
    },
    ar: {
      name: "Motorola",
      parentCompany: "Motorola",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Always",
      parentCompany: "P&G",
      alternatives: ["Molped"],
    },
    tr: {
      name: "Always",
      parentCompany: "P&G",
      alternatives: ["Molped"],
    },
    ar: {
      name: "أولويز",
      parentCompany: "P&G",
      alternatives: ["Molped","Lady soft"],
    }
  },
  {
    en: {
      name: "Dash",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Dash",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "Dash",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dreft",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Dreft",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "Dreft",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mr.Clean",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Mr.Clean",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "Mr.Clean",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ariel",
      parentCompany: "P&G",
      alternatives: ["Bor-on "],
    },
    tr: {
      name: "Ariel",
      parentCompany: "P&G",
      alternatives: ["Bor-on "],
    },
    ar: {
      name: "اريال",
      parentCompany: "P&G",
      alternatives: ["Bor-on "],
    }
  },
  {
    en: {
      name: "Bounty",
      parentCompany: "P&G",
      alternatives: ["waff star","Lagoon"],
    },
    tr: {
      name: "Bounty",
      parentCompany: "P&G",
      alternatives: ["waff star"],
    },
    ar: {
      name: "باونتي",
      parentCompany: "P&G",
      alternatives: ["waff star","Lagoon"],
    }
  },
  {
    en: {
      name: "Braun",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Braun",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "براون",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Charmin",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Charmin",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "تشارمن",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Crest",
      parentCompany: "P&G",
      alternatives: ["Experdent","EyüpSabriTuncer"],
    },
    tr: {
      name: "Crest",
      parentCompany: "P&G",
      alternatives: ["Experdent","EyüpSabriTuncer"],
    },
    ar: {
      name: "كرست ",
      parentCompany: "P&G",
      alternatives: ["ExperdentEyüpSabriTuncer"],
    }
  },
  {
    en: {
      name: "Dawn",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Dawn",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "داون",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Downy",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Downy",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "داوني",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fairy",
      parentCompany: "P&G",
      alternatives: ["Porçoz"],
    },
    tr: {
      name: "Fairy",
      parentCompany: "P&G",
      alternatives: ["Porçoz"],
    },
    ar: {
      name: "فيري",
      parentCompany: "P&G",
      alternatives: ["Porçoz"],
    }
  },
  {
    en: {
      name: "Febreze",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Febreze",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "فيبريز",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Gillette",
      parentCompany: "P&G",
      alternatives: ["Derby","Arko nem"],
    },
    tr: {
      name: "Gillette",
      parentCompany: "P&G",
      alternatives: ["Derby","Arko nem"],
    },
    ar: {
      name: "جيليت",
      parentCompany: "P&G",
      alternatives: ["Derby","Arko nem"],
    }
  },
  {
    en: {
      name: "Head & Shoulders",
      parentCompany: "P&G",
      alternatives: ["Dermokil"],
    },
    tr: {
      name: "Head & Shoulders",
      parentCompany: "P&G",
      alternatives: ["Dermokil"],
    },
    ar: {
      name: "هيد اند شولدرز",
      parentCompany: "P&G",
      alternatives: ["Dermokil"],
    }
  },
  {
    en: {
      name: "Olay",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Olay",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "اولاي",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Old Spice",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Old Spice",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "أولد سبايس",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Oral-B",
      parentCompany: "P&G",
      alternatives: ["Natural","Experdent","EyüpSabriTunce"],
    },
    tr: {
      name: "Oral-B",
      parentCompany: "P&G",
      alternatives: ["Natural","Experdent","EyüpSabriTunce"],
    },
    ar: {
      name: "اورال بي",
      parentCompany: "P&G",
      alternatives: ["Natural","Experdent","EyüpSabriTunce"],
    }
  },
  {
    en: {
      name: "Pampers",
      parentCompany: "P&G",
      alternatives: ["Molfix", "Baby Turco"],
    },
    tr: {
      name: "Pampers",
      parentCompany: "P&G",
      alternatives: ["Molfix", "Baby Turco"],
    },
    ar: {
      name: "بامبرز",
      parentCompany: "P&G",
      alternatives: ["Molfix", "Baby Turco"],
    }
  },
  {
    en: {
      name: "Pantene",
      parentCompany: "P&G",
      alternatives: ["Bioxcin"],
    },
    tr: {
      name: "Pantene",
      parentCompany: "P&G",
      alternatives: ["Bioxcin"],
    },
    ar: {
      name: "بانتين",
      parentCompany: "P&G",
      alternatives: ["Bioxcin"],
    }
  },
  {
    en: {
      name: "SK-II",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "SK-II",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "SK-II",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Swiffer",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Swiffer",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "سويفر",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tampax",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Tampax",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "تامباكس",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Alo",
      parentCompany: "P&G",
      alternatives: ["Abc"],
    },
    tr: {
      name: "Alo",
      parentCompany: "P&G",
      alternatives: ["Abc"],
    },
    ar: {
      name: "ألو",
      parentCompany: "P&G",
      alternatives: ["Abc"],
    }
  },
  {
    en: {
      name: "Orkid",
      parentCompany: "P&G",
      alternatives: ["Molped"],
    },
    tr: {
      name: "Orkid",
      parentCompany: "P&G",
      alternatives: ["Molped"],
    },
    ar: {
      name: "أوركيد",
      parentCompany: "P&G",
      alternatives: ["Molped"],
    }
  },
  {
    en: {
      name: "Herbal Essences",
      parentCompany: "P&G",
      alternatives: ["Bioxcin"],
    },
    tr: {
      name: "Herbal Essences",
      parentCompany: "P&G",
      alternatives: ["Bioxcin"],
    },
    ar: {
      name: "هيربل اسنسز",
      parentCompany: "P&G",
      alternatives: ["Bioxcin"],
    }
  },
  {
    en: {
      name: "ipana",
      parentCompany: "P&G",
      alternatives: ["Experdent","EyüpSabriTunce"],
    },
    tr: {
      name: "ipana",
      parentCompany: "P&G",
      alternatives: ["Experdent","EyüpSabriTunce"],
    },
    ar: {
      name: "ايبانا",
      parentCompany: "P&G",
      alternatives: ["Experdent","EyüpSabriTunce"],
    }
  },
  {
    en: {
      name: "Vicks",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Vicks",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "فيكس ",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kadınlar bilir",
      parentCompany: "P&G",
      alternatives: [],
    },
    tr: {
      name: "Kadınlar bilir",
      parentCompany: "P&G",
      alternatives: [],
    },
    ar: {
      name: "Kadınlar bilir",
      parentCompany: "P&G",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Danone",
      parentCompany: "Danone",
      alternatives: ["Sutaşmiş"],
    },
    tr: {
      name: "Danone",
      parentCompany: "Danone",
      alternatives: ["Sutaşmiş"],
    },
    ar: {
      name: "دانون",
      parentCompany: "Danone",
      alternatives: ["Sutaşmiş"],
    }
  },
  {
    en: {
      name: "Damla Su",
      parentCompany: "Coca Cola",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    tr: {
      name: "Damla Su",
      parentCompany: "Coca Cola",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    ar: {
      name: "داملا سو",
      parentCompany: "Coca Cola",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    }
  },
  {
    en: {
      name: "Erikli",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    tr: {
      name: "Erikli",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    ar: {
      name: "إيريكلي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nestle,
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    }
  },
  {
    en: {
      name: "Hayat Su",
      parentCompany: "Danone",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    tr: {
      name: "Hayat Su",
      parentCompany: "Danone",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    ar: {
      name: "هايات سو",
      parentCompany: "Danone",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    }
  },
  {
    en: {
      name: "Sırma",
      parentCompany: "Danone",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    tr: {
      name: "Sırma",
      parentCompany: "Danone",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    ar: {
      name: "سرما",
      parentCompany: "Danone",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    }
  },
  {
    en: {
      name: "Akmina",
      parentCompany: "Danone",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    tr: {
      name: "Akmina",
      parentCompany: "Danone",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    },
    ar: {
      name: "أكمينا",
      parentCompany: "Danone",
      alternatives: ["Hamidiye", "Fuska", "Assu", "Özkaynak", "Buzdağı", "Taşkesti", "Saka", "Uludağ"]
    }
  },
  {
    en: {
      name: "Cognis",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Cognis",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "كوجنيس",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "GLISS",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "GLISS",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "GLISS",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Loctite",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Loctite",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "لوكتيت",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Persil",
      parentCompany: "Henkel",
      alternatives: ["Peros"],
    },
    tr: {
      name: "Persil",
      parentCompany: "Henkel",
      alternatives: ["Peros"],
    },
    ar: {
      name: "بيرسيل",
      parentCompany: "Henkel",
      alternatives: ["Peros"],
    }
  },
  {
    en: {
      name: "Pril",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Pril",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "بريل",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dixi",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Dixi",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "ديكسي",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fa",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Fa",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "فا",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sellotape",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Sellotape",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "سيلوتيب",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Schwarzkopf",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Schwarzkopf",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "شوارزكوبف",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Schauma",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Schauma",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "شاوما",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Pritt",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Pritt",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "بريت",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bref",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Bref",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "بريف",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Vernel",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Vernel",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "فيرنيل",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Perwoll",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Perwoll",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "بيروول",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ponal",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Ponal",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "بونال",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Seborin",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Seborin",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "سيبورين",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Somat",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Somat",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "سومات",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Pattex",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Pattex",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "باتيكس",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Purex",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Purex",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "بوريكس",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "20 Mule Team Borax",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "20 Mule Team Borax",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "٢٠ مول تيم بوراكس",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Boraxo",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Boraxo",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "بوراكسو",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dial",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Dial",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "دايل",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dry Idea",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Dry Idea",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "دراي آديا",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Fels-Naptha",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Fels-Naptha",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "فيلس-نابثا",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tursil",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Tursil",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "تورسيل",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Renuzit",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Renuzit",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "رينوزيت",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Right Guard",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Right Guard",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "رايت جارد",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Coast",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Coast",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "كوست",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Wisk",
      parentCompany: "Henkel",
      alternatives: [],
    },
    tr: {
      name: "Wisk",
      parentCompany: "Henkel",
      alternatives: [],
    },
    ar: {
      name: "ويسك",
      parentCompany: "Henkel",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Actimel",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Actimel",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "أكتيميل",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Activia",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Activia",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "أكتيفيا",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Badoit",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Badoit",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "بادويت",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Danino",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Danino",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "دانينو",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Danette",
      parentCompany: "Danone",
      alternatives: ["daphne"]
    },
    tr: {
      name: "Danette",
      parentCompany: "Danone",
      alternatives: ["daphne"]
    },
    ar: {
      name: "دانيت",
      parentCompany: "Danone",
      alternatives: ["daphne"]
    }
  },
  {
    en: {
      name: "Danissimo",
      parentCompany: "Danone",
      alternatives: ["dost "]
    },
    tr: {
      name: "Danissimo",
      parentCompany: "Danone",
      alternatives: ["dost "]
    },
    ar: {
      name: "دانيسيمو",
      parentCompany: "Danone",
      alternatives: ["dost "]
    }
  },
  {
    en: {
      name: "Tikveşli",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Tikveşli",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "تيكفيشلي",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Alpro",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Alpro",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "ألبرو",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Brown Cow",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Brown Cow",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "براون كاو",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cow & Gate",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Cow & Gate",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "كاو أند جيت",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Earthbound Farm",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Earthbound Farm",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "إيرث فارم",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Évian",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Évian",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "إيفيان",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Horizon Organic",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Horizon Organic",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "هورايزن أورجانيك",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "International Delight",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "International Delight",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "إنتيرناشونال ديلايت",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Land O'Lakes",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Land O'Lakes",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "لاند أوليكس",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Lattella",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Lattella",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "لاتيلا",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Michel et Augustin",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Michel et Augustin",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "مايكل أي أوجستن",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nutricia",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Nutricia",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "نوتريشيا",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Milupa",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Milupa",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "ميلوبا",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Provamel",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Provamel",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "بروفاميل",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Silk",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Silk",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "سيلك",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Oikos",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Oikos",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "أويكوس",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Vega",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Vega",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "فيجا",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Volvic",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Volvic",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "فولفيك",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Delight",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Delight",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "ديلايت",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "YoPRO",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "YoPRO",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "يوبرو",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Font Vella",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Font Vella",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "فونت فيلا",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Salus",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Salus",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "سالوس",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mizone",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Mizone",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "ميزون",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Zywiec Zdroj",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Zywiec Zdroj",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "زيويك زدروج",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Villavicencio",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Villavicencio",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "فيلافيسينشيو",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Villa del Sur",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Villa del Sur",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "فيلا ديل سور",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bonafont",
      parentCompany: "Danone",
      alternatives: [],
    },
    tr: {
      name: "Bonafont",
      parentCompany: "Danone",
      alternatives: [],
    },
    ar: {
      name: "بونافون",
      parentCompany: "Danone",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Reckitt",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Reckitt",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Reckitt",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Air Wick",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Air Wick",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ايرويك",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Calgon",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Calgon",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "كالجون",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cillit Bang",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Cillit Bang",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "جيليت بانغ",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Glass Plus",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Glass Plus",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "جلاس بلس",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dettol",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Dettol",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ديتول",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Durex",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Durex",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "دوريكس",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Finish",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Finish",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "فينيش",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Harpic",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Harpic",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "هاربيك",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Lemsip",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Lemsip",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ليمسيب",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Lysol",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Lysol",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ليسول",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mortein",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Mortein",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "مورتين",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mucinex",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Mucinex",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Mucinex",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Nurofen",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Nurofen",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "نوروفين",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Scholl",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Scholl",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Scholl",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Strepsils",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Strepsils",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ستريبسلز",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Vanish (Kosla)",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Vanish (Kosla)",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "فانيش (Kosla)",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Veet",
      parentCompany: "Reckitt",
      alternatives: ["Sesu","Lure"],
    },
    tr: {
      name: "Veet",
      parentCompany: "Reckitt",
      alternatives: ["Sesu","Lure"],
    },
    ar: {
      name: "فييت",
      parentCompany: "Reckitt",
      alternatives: ["Sesu","Lure"],
    }
  },
  {
    en: {
      name: "Aerogard",
      parentCompany: "Reckitt",
      alternatives: ["Ülker"],
    },
    tr: {
      name: "Aerogard",
      parentCompany: "Reckitt",
      alternatives: ["Ülker"],
    },
    ar: {
      name: "ايروغارد",
      parentCompany: "Reckitt",
      alternatives: ["Ülker"],
    }
  },
  {
    en: {
      name: "Amphyl",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Amphyl",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Amphyl",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Bonjela",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Bonjela",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "بونجيلا",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Brasso",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Brasso",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "براسو",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Calgonit",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Calgonit",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Calgonit",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cattlemen's",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Cattlemen's",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Cattlemen's",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cēpacol",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Cēpacol",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Cēpacol",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Ceraclen",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Ceraclen",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Ceraclen",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cherry Blossom",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Cherry Blossom",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Cherry Blossom",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Clean and Smooth",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Clean and Smooth",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "كلين اند سموث",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Clearasil",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Clearasil",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Clearasil",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cling",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Cling",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "كلينج",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cling Free",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Cling Free",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "كلينج فري",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Cobra Brilliant Shiner",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Cobra Brilliant Shiner",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Cobra Brilliant Shiner",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Colon",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Colon",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "كولون",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Coral",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Coral",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "كورال",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Delsym",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Delsym",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Delsym",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dermicool",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Dermicool",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Dermicool",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Digestive Advantage",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Digestive Advantage",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Digestive Advantage",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Disprin",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Disprin",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ديسبرين",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Dosia",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Dosia",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "دوسيا",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "ايزي اوف",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Easy-Off",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "Easy-Off",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Easy On",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Easy On",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ايزي اون",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Elena",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Elena",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ايلينا",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Hoffmann's",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Hoffmann's",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "هوفمانز",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kalia",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Kalia",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "كاليا",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kaltron",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Kaltron",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "كالترون",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Marc",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Marc",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "مارك",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mr Sheen",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Mr Sheen",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "مستر شين",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Mr. Min",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Mr. Min",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "مستر مين",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Sani Flush",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Sani Flush",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ساني فلاش",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Wenol",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Wenol",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "وينول",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Woolite",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Woolite",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "وولايت",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Resolve",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    tr: {
      name: "Resolve",
      parentCompany: "Reckitt",
      alternatives: [],
    },
    ar: {
      name: "ريزولف",
      parentCompany: "Reckitt",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Erpiliç",
      parentCompany: "Er Piliç Entegre",
      alternatives: [],
    },
    tr: {
      name: "Erpiliç",
      parentCompany: "Er Piliç Entegre",
      alternatives: [],
    },
    ar: {
      name: "Erpiliç",
      parentCompany: "Er Piliç Entegre",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Kervansaray",
      parentCompany: "Kervansaray",
      alternatives: [],
    },
    tr: {
      name: "Kervansaray",
      parentCompany: "Kervansaray",
      alternatives: [],
    },
    ar: {
      name: "Kervansaray",
      parentCompany: "Kervansaray",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Koska",
      parentCompany: "Merter Helva",
      alternatives: [],
    },
    tr: {
      name: "Koska",
      parentCompany: "Merter Helva",
      alternatives: [],
    },
    ar: {
      name: "Koska",
      parentCompany: "Merter Helva",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Çaykur",
      parentCompany: "Çaykur",
      alternatives: [],
    },
    tr: {
      name: "Çaykur",
      parentCompany: "Çaykur",
      alternatives: [],
    },
    ar: {
      name: "Çaykur",
      parentCompany: "Çaykur",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Didi",
      parentCompany: "Çaykur",
      alternatives: [],
    },
    tr: {
      name: "Didi",
      parentCompany: "Çaykur",
      alternatives: [],
    },
    ar: {
      name: "ديدي",
      parentCompany: "Çaykur",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Tadella",
      parentCompany: "Sagra Grup",
      alternatives: [],
    },
    tr: {
      name: "Tadella",
      parentCompany: "Sagra Grup",
      alternatives: [],
    },
    ar: {
      name: "Tadella",
      parentCompany: "Sagra Grup",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Onsa",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.altinbasHolding,
      alternatives: [],
    },
    tr: {
      name: "Onsa",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.altinbasHolding,
      alternatives: [],
    },
    ar: {
      name: "Onsa",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.altinbasHolding,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Nuh Makarna",
      parentCompany: "Nuh’un Ankara Makarnası",
      alternatives: [],
    },
    tr: {
      name: "Nuh Makarna",
      parentCompany: "Nuh’un Ankara Makarnası",
      alternatives: [],
    },
    ar: {
      name: "Nuh Makarna",
      parentCompany: "Nuh’un Ankara Makarnası",
      alternatives: [],
    }
  },
  {
    en: {
      name: "Akdo",
      parentCompany:PARENT_COMPANIES_NAMES_MAP.akdoGida,
      alternatives: [],
    },
    tr: {
      name: "Akdo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.akdoGida,
      alternatives: [],
    },
    ar: {
      name: "Akdo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.akdoGida,
      alternatives: [],
    }
  },
  {
    en: {
      name: "Eriş Un",
      parentCompany: "Erişler Gıda",
      alternatives: [],
    },
    tr: {
      name: "Eriş Un",
      parentCompany: "Erişler Gıda",
      alternatives: [],
    },
    ar: {
      name: "Eriş Un",
      parentCompany: "Erişler Gıda",
      alternatives: [],
    },
  },
  {
    en: {
      name: "Kelloggs",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Kelloggs",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كيلوجز",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Coco Pops",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Coco Pops",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كوكو بوبس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Corn Flakes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Corn Flakes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كورن فليكس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Granola",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Granola",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "جارنولا",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Honey Pops",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Honey Pops",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "هوني بوبس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Special K",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Special K",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "سبيشل ك",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Tresor",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Tresor",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "تريسور",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "All Bran",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "All Bran",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "أل-بران",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Austin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Austin",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "أوستن",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Availability",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Availability",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "أفيليبيليتي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Bear Naked, Inc.",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Bear Naked, Inc.",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "بيرد نيكيد انك",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Carr’s",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Carr’s",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كارس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Cheez-It",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Cheez-It",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "تشيز إت",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Cheez-It Crackers",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Cheez-It Crackers",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "تشيز إت كراكرز",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Chips Deluxe",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Chips Deluxe",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "شيبس ديلوكس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Club Crackers",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Club Crackers",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كلب كراكرز",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Coco Pops/Chocos",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Coco Pops/Chocos",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كوكو بوبس شوكوس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Corn Pops",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Corn Pops",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كورن بوبس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Crunchy Nut",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Crunchy Nut",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كرانشي نات",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Eggo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Eggo",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "إيجو",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Famous Amos",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Famous Amos",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "فيموس إيموس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "FiberPlus",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "FiberPlus",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "فيابر بلاس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Froot Loops",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Froot Loops",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "فروت لوبس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Frosted Flakes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Frosted Flakes",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "فروستيد فليكس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Fruit Winders",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Fruit Winders",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "فروت وينديرز",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Fruity Snacks",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Fruity Snacks",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "فروتي سناكس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Gardenburger",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Gardenburger",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "جاردين برجر",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Kashi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Kashi",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كاشي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Keebler",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Keebler",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كيلبير",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Krave",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Krave",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "كريف",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Mini Wheats",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Mini Wheats",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "ميني ويت",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Morning Star Foods",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Morning Star Foods",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "مورننج ستار فودز",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Morningstar Farms",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Morningstar Farms",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "مورننج ستار فارمز",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Mother’s Cookies",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Mother’s Cookies",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "موثير كوكيس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Murray",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Murray",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "موراي",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Nutri-Grain",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Nutri-Grain",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "نوتري-جرين",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Protecto & Gamble",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Protecto & Gamble",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "بروتيكتو أند جامبل",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Pop-Tarts",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Pop-Tarts",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "بوب تارتس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Pringles",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Pringles",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "برينجلز",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Rice Krispies",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Rice Krispies",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "رايس كريسبيس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Sunshine Biscuits",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Sunshine Biscuits",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "سانشاين بيسكيتس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Town House",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Town House",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "تاون هاوس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Yogos",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Yogos",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "يوجوس",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Zesta Crackers",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    tr: {
      name: "Zesta Crackers",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
    ar: {
      name: "زيتا كراكرز",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.kelloggs,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Ferrero",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: [],
    },
    tr: {
      name: "Ferrero",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: [],
    },
    ar: {
      name: "فيريرو",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Kinder",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: [],
    },
    tr: {
      name: "Kinder",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: [],
    },
    ar: {
      name: "كيندر",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Nutella",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: ["Torku"],
    },
    tr: {
      name: "Nutella",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: ["Torku"],
    },
    ar: {
      name: "نوتيلا",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: ["Torku"],
    },
  },
  {
    en: {
      name: "Raffaello",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: [],
    },
    tr: {
      name: "Raffaello",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: [],
    },
    ar: {
      name: "رافاييلو",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.ferrero,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Gloria Jeans",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.gloriaJeans,
      alternatives: [],
    },
    tr: {
      name: "Gloria Jeans",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.gloriaJeans,
      alternatives: [],
    },
    ar: {
      name: "Gloria Jeans",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.gloriaJeans,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Nevada",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nevcoHolding,
      alternatives: [],
    },
    tr: {
      name: "Nevada",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nevcoHolding,
      alternatives: [],
    },
    ar: {
      name: "Nevada",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.nevcoHolding,
      alternatives: [],
    },
  },
  {
    en: {
      name: "Altınbaş Holding",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.altinbasHolding,
      alternatives: [],
    },
    tr: {
      name: "Altınbaş Holding",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.altinbasHolding,
      alternatives: [],
    },
    ar: {
      name: "Altınbaş Holding",
      parentCompany: PARENT_COMPANIES_NAMES_MAP.altinbasHolding,
      alternatives: [],
    },
  },
]);
