import React from "react";
import "./Footer.scss";
import t from "../i18t";

const Footer = ({language, isMobile}) => {
  return (
    <footer className="footer">
      <div className="footer-content-wrapper">
        <div className="clarification">
          <span className="info">{t[language].footer.info}</span>
          <ul className="cretirias">
            {t[language].footer.criterias.map((criteria, index) => {
              return <li key={index}>{criteria}</li>;
            })}
          </ul>
          <span>{t[language]?.footer.conclusion}</span>
        </div>
        <div className="qr-code-wrapper">
          <img src="../images/boycottoptions_qr_code.png" alt="QR Code" className="qr-code" />
        </div>
      </div>
      <div className="copy-rights-wrapper">
        <div className="copy-rights">
          <span>&copy; {new Date().getFullYear()} Boycott Options</span>
        </div>
        <div className="links">
          {!isMobile && "|"}
          <a href="https://forms.gle/h6kEedGNMBDvkWqX7" className="link" target="_blank" rel="noopener noreferrer">
            {t[language].contact.form}
          </a>
            |
          <a href="https://forms.gle/RpBkJJ85AoDTAa556" className="link" target="_blank" rel="noopener noreferrer">
            {t[language].contact.feedback}
          </a>
            |
          <a href="mailto:boycottoptions@gmail.com" className="link" target="_blank" rel="noopener noreferrer">
            {t[language].contact.email}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
