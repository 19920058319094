import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUser } from "@fortawesome/free-regular-svg-icons";
import "./Views.scss";
import t from "../i18t";

const Views = ({isLoading=true, data, language}) => {
  return (
    <div className="views-wrapper">
      {isLoading?
        <div className="sp sp-circle"></div>
        : <>
          <div className="users" title="users">
            <FontAwesomeIcon icon={faUser} className="icon"/>
            <span>{data.users} {t[language].users}</span>
          </div>
            |
          <div className="views" title="views">
            <FontAwesomeIcon icon={faEye} className="icon" />
            <span>{data.views} {t[language].visits}</span>
          </div>
        </>
      }
    </div>
  );
};

export default Views;