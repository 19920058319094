import React from "react";
import "./SiteDescription.scss";
import t from "../i18t";

const SiteDescription = ({language}) => {

  return (
    <div className="site-description">
      <p>
        {t[language]?.siteDescription}
      </p>
      {/* <p>
        {t[language]?.productsListIsNotCompleted}
      </p> */}
    </div>
  );
};

export default SiteDescription;
