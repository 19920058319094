import React from "react";
import "./NoResult.scss";
import t from "../i18t";

const SiteDescription = ({suggestBrand, language}) => {

  const renderedContent = () => {
    const isRtl = language === "ar";
    return (
      <span>{t[language].noResult}
        <button className={`suggest-brand-button ${isRtl && "mr-4"}`} onClick={() => suggestBrand()}>
          {`${t[language]?.clickHere}`}
        </button>.
      </span>
    );
  };

  return (
    <div className="no-result">
      {renderedContent()}
    </div>
  );
};

export default SiteDescription;
