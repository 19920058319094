export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const convertTurkishToLatin = (text) => {
  const turkishChars = {
    'ı': 'i', 'İ': 'i', 'i̇': 'i', 'ş': 's', 'Ş': 's', 'ğ': 'g', 'Ğ': 'g', 'ü': 'u', 'Ü': 'u', 'ö': 'o', 'Ö': 'o', 'ç': 'c', 'Ç': 'c'
  };

  const turkishPattern = new RegExp(Object.keys(turkishChars).join('|'), 'g');

  return text.replace(turkishPattern, (char) => {
    return turkishChars[char] || char;
  });
};

const commonArabicCharsMapping = { 'أ': 'ا', 'ئ': 'ي', 'ى': 'ي', 'ه': 'ة', 'ذ': 'ز', 'ط': 'ت', 'س': 'ز', 'ي': '', 'ا' : '', 'غ' : 'ج' };

const arabicCharsMapping = {
  'ا': 'a', 'أ': 'a', 'إ': 'i', 'آ': 'a', 'ء': 'a', 'ب': 'b', 'ت': 't', 'ث': 's', 'ج': 'j', 'ح': 'h',
  'خ': 'kh', 'د': 'd', 'ذ': 'z', 'ر': 'r', 'ز': 'z', 'س': 's', 'ش': 'sh', 'ص': 's', 'ض': 'd', 'ط': 't',
  'ظ': 'z', 'ع': 'a', 'غ': 'gh', 'ف': 'f', 'ق': 'q', 'ك': 'k', 'ل': 'l', 'م': 'm', 'ن': 'n', 'ه': 'h',
  'و': 'o', 'ي': 'y', 'ى': 'a', 'ة': 'h', 'ؤ': 'o', 'ئ': 'y', 'آ': 'a', 'ء': 'a'
};

const arabicPattern = new RegExp(Object.keys(arabicCharsMapping).join('|'), 'g');

export const convertArabicCommonChars = (text) => {
  return text.replace(RegExp(Object.keys(commonArabicCharsMapping).join('|'), 'g'), (letter) => commonArabicCharsMapping[letter]);
};

export const convertArabicToLatin = (text) => {
  return text.replace(arabicPattern, (char) => arabicCharsMapping[char] || char);
};

export const ignoreSpecialChars = (text) => {
  return text.replace(/[-&.#@!*=+;:'"`()~]/g, '');
};

export const ignoreSpaces = (text) => {
  return text.replace(/[ ]/g, '');
};

export const prepareForSearch = (text, language) => {
  const preparedString = language === 'ar'
  ? convertArabicToLatin(
    convertArabicCommonChars(text)
    )
  : text;

  return ignoreSpecialChars(
    convertTurkishToLatin(
      ignoreSpaces(preparedString)
      )
    ).toLowerCase();
};


export const isIncludesBrandName = (searchKey, item, language) => {
  return  prepareForSearch(item.en.name, language)?.includes(prepareForSearch(searchKey, language))
  || prepareForSearch(item.ar.name, language)?.includes(prepareForSearch(searchKey, language)) 
  || prepareForSearch(searchKey, language)?.includes(prepareForSearch(item.en.name, language)) 
  || prepareForSearch(searchKey, language)?.includes(prepareForSearch(item.ar.name, language)) 
};

export const isIncludesParentCompanyName = (searchKey, item, language) => {
  return  prepareForSearch(item.en?.parentCompany, language) === prepareForSearch(searchKey, language)
  || prepareForSearch(item.ar?.parentCompany, language) === prepareForSearch(searchKey, language)
  || prepareForSearch(searchKey, language) === prepareForSearch(item.en?.parentCompany, language)
  || prepareForSearch(searchKey, language) === prepareForSearch(item.ar?.parentCompany, language)
};

export const getUniqueBrandList = (brands, language) => {
  const uniqueBrands = new Set();

  return brands.filter(brand => {
    if (!uniqueBrands.has(brand[language].name)) {
      uniqueBrands.add(brand[language].name);
      return true;
    }
    return false;
  });
};