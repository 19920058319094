import React, { useEffect, useState } from "react";
import axios from "axios";
import { data, PROPERTIES_ENUM, PARENT_COMPANIES_DATA_MAP } from "../constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Views from "../components/Views";
import NoResult from "../components/NoResult";
import SiteDescription from "../components/SiteDescription";
import t from "../i18t";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { getUniqueBrandList, ignoreSpecialChars, isIncludesBrandName, isIncludesParentCompanyName, prepareForSearch } from "../utils";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

const Home = ({language}) => {
  const [searchKey, setSearchKey] = useState("");
  const [expandedItem, setExpandedItem] = useState(null);
  const [expandedChild, setExpandedChild] = useState(null);
  const [views, setViews] = useState(0);
  const [users, setUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = window.innerWidth < 768;
  const defaulListedProductsCount = isMobile ? 10 : 30;
  const [listedProductCount, setListedProductCount] = useState(defaulListedProductsCount);
  const [filteredData, setFilteredData] = useState(getUniqueBrandList(data, language).sort((a, b) => a[language].name.localeCompare(b[language].name)));
  const [suggestedAlternative, setSuggestedAlternative] = useState('');

  const handleItemClick = (index) => {
    setExpandedChild(null);
    if (expandedItem === index) {
      setExpandedItem(null);
    } else {
      setExpandedItem(index);
    }
  };

  const handleChildClick = (propery) => {
    if(propery !== expandedChild) setExpandedChild(propery);
    else setExpandedChild(null);
  };

  const handleFilterChange = (e) => {
    setListedProductCount(defaulListedProductsCount);
    const _searchKey = prepareForSearch(e.target.value, language);

    const _filteredData = getUniqueBrandList(data, language).filter(
      (item) => {
        if (['boycott', 'boykot', 'مقاطعة'].includes(e.target.value)) {
          return PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isBoycott
        }
        if (['alternatives', 'alternatifler', 'البدائل'].includes(e.target.value)) {
          return !PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isBoycott
        }
        return isIncludesBrandName(_searchKey, item, language) || isIncludesParentCompanyName(_searchKey, item, language);
      }
    ).sort((a, b) => a[language].name.localeCompare(b[language].name));

    setExpandedItem(null);
    setSearchKey(e.target.value);

    setFilteredData(_filteredData);

    if (_filteredData.length === 0 && searchKey.trim().toLocaleLowerCase() !== _searchKey) {
      axios.post("https://boycottoptions.com/services/searchRecords.php", {searchKey: ignoreSpecialChars(searchKey)})
        .then(response => {
        })
        .catch(error => {
        });
    }
  };

  const handleSuggestBrand = () => {
    notify("success", t[language].thanksForSuggestion);
    axios.post("https://boycottoptions.com/services/suggestBrand.php", {brand: ignoreSpecialChars(searchKey)})
      .then(response => {
      })
      .catch(error => {
      });
  };
  const handleSuggestAlternativeChange = (e) => {
    const _suggestedAlternative = e.target.value;
    setSuggestedAlternative(_suggestedAlternative);
  };

  const handleSuggestAlternative = (boycotted, alternative) => {
    axios.post("https://boycottoptions.com/services/suggestAlternative.php", {boycotted, alternative: alternative})
      .then(response => {
        notify("success", t[language].thanksForSuggestion);
        setSuggestedAlternative('');
      })
      .catch(error => {
      });
  };

  const showMore = () => {
    setListedProductCount(listedProductCount + 15);
  };

  const renderedContent = filteredData.length > 0 ?
    filteredData.slice(0, listedProductCount).map((item, index) => {
      const randomNumber = Math.floor(Math.random() * 5) + 1;
      return (
        <div key={index} className={`data-item ${expandedItem === index ? 'expanded': ''} ${PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isBoycott? 'boycott-brand': ''} ${PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isNeutral? 'neutral-brand': ''}`}>
          <div onClick={() => handleItemClick(index)} className="item-header">
            {PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isBoycott &&
            <>
              <span className={`drop --${randomNumber}`}></span>
              <span className={`drop --${randomNumber}`}></span>
              <span className={`drop --${randomNumber}`}></span>
              <span className={`drop --${randomNumber}`}></span>
            </>
            }
            <span>{item[language].name}</span>
            <span>{expandedItem === index ? "-" : "+"}</span>
          </div>
          {expandedItem === index &&
          <div className="item-description">
            <div className="property-row">
              <div className="property-label clickable">
                <span>{t[language].isSupport}</span>
                <span className={`property-value is-boycott ${PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isBoycott && "boycott-brand"} ${PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isNeutral && "neutral-brand"}`}>
                  {!PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isNeutral? PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isBoycott ? t[language].yes : t[language].no : t[language].neutral}
                </span>
              </div>
            </div>
            <div className="property-row">
              <div className="property-label clickable">
                <span>{t[language].parentCompany}: </span>
                <span className="property-value">
                  {item[language].parentCompany}
                </span>
              </div>
            </div>
            {(PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isBoycott || PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.isNeutral) &&
              <>
                <div className="property-row" onClick={() => handleChildClick(PROPERTIES_ENUM.HOW)}>
                  <div className="property-label clickable">
                    <span>{t[language].how}</span>
                    <span>{expandedChild === PROPERTIES_ENUM.HOW ? "-" : "+"}</span>
                  </div>
                  {expandedChild === PROPERTIES_ENUM.HOW &&
                  <>
                    <span className="property-value">
                      {PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.reason[language]}

                      {!!PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.infoLink &&
                        <a className="info-link" href={PARENT_COMPANIES_DATA_MAP[item.en.parentCompany]?.infoLink} target="_blank" rel="noreferrer">{t[language].moreInfo}</a>
                      }
                    </span>
                  </>
                  }
                </div>
                <div className="property-row">
                  <div className="property-label clickable" onClick={() => handleChildClick(PROPERTIES_ENUM.ALTERNATIVES)}>
                    {<span>
                      {t[language].alternatives}
                    </span>}
                    <span>{expandedChild === PROPERTIES_ENUM.ALTERNATIVES ? "-" : "+"}</span>
                  </div>
                  {expandedChild === PROPERTIES_ENUM.ALTERNATIVES &&
                  <>
                    <span className="property-value">{
                      item[language].alternatives.length >= 1 
                      ? item[language].alternatives.join(", ")
                      : t[language].noAlternative.text
                    }</span>
                    <form className="suggest-alternative" onSubmit={e => {e.preventDefault(); handleSuggestAlternative(item[language].name, suggestedAlternative);}}>
                        <input className="suggest-alternative-input" placeholder={t[language].noAlternative.placeholder} value={suggestedAlternative} onChange={handleSuggestAlternativeChange} autofocus required />
                        <button type="submit" className="suggest-alternative-button"><FontAwesomeIcon icon={faPaperPlane} /></button>
                    </form>
                  </>
                  }
                </div>
              </>
            }
          </div>
          }
        </div>
      );}
    ) :
    <NoResult suggestBrand={handleSuggestBrand} language={language}/>
  ;

  useEffect(() => {
    setIsLoading(true);
    axios.get("https://boycottoptions.com/services/getViews.php")
      .then(response => {
        const [_views, _users] = [+response.data?.views, +response.data?.users];
        setViews(_views);
        setUsers(_users);

        axios.put("https://boycottoptions.com/services/updateViews.php", {views: _views + 1})
          .then(response => {
          })
          .catch(error => {
          });

        setIsLoading(false);
      })
      .catch(error => {
      });
  },[]);

  const notify = (type, message) => {
    toast[type](message);
  };

  return (
    <div className="app">
      <ToastContainer />
      <SiteDescription language={language} />
      <div className="search-wrapper">
        <input
          type="text"
          placeholder={t[language]?.searchBrand(Object.keys(data).length)}
          value={searchKey}
          onChange={handleFilterChange}
          autoFocus
        />
      </div>
      <div className="content-wrapper">
        <div className="brands-wrapper">
          {renderedContent}
        </div>
      </div>
      {listedProductCount < filteredData.length && (
        <>
        <div className="show-more" onClick={() => showMore()}>
          <FontAwesomeIcon icon={faChevronDown} />
          {`${t[language].showMore} (${filteredData.length - listedProductCount})`}
        </div>
        </>
      )}
      {<Views isLoading={isLoading} data={{views, users}} language={language}/>}
      <Footer language={language} isMobile={isMobile} />
    </div>
  );
};

export default Home;
