const t = {
  en: {
    siteDescription: `Boycotts are one of the most effective responses we can do. 
        To see which brands do or do not support the Israeli occupation of Palestine, enter the product/brand name and click the + sign. If the brand you're looking for is boycotted, you can see local alternatives if they're available.`,
    noResult: "No results found. If you would like us to review the brand you entered in the search bar, please ",
    clickHere: "click here",
    searchBrand: (brandsNumber) => `Search among ${brandsNumber} brands...`,
    isSupport: "Does it support Israel?",
    yes: "Yes",
    no: "No",
    parentCompany: "Parent Company",
    how: "How?",
    moreInfo: "more info",
    alternatives: "Alternatives",
    brands: "Brands:",
    users: "users",
    visits: "visits",
    thanksForSuggestion: "Your request has been received, thank you for your suggestion!",
    footer: {
      info: `Although every effort is made to ensure the accuracy of information published on the boycottoptions.com website, we cannot guarantee its absolute accuracy. boycottoptions.com is not liable for any errors or damages, be they material or moral, that may arise. The brands categorized as "Supports Israel" are selected based on the following criteria:
            By using this site, all users are understood to have accepted these terms.`,
      criterias: [
        "Directly supports the occupying Israeli government",
        "A significant contribution to the Israeli economy",
      ],
      conclusion: "By using this website, you agree to the above terms."
    },
    contact: {
      form: "Contact Form",
      email: "Email",
      feedback: "Feedback",
    },
    languages: {
      en: "EN",
      tr: "TR",
      ar: "العربية"
    },
    showMore: "Show more",
    productsListIsNotCompleted: "Note: Our data is not complete yet, we are adding the missing brands and products continuously.",
    neutral: "Neutral",
    noAlternative: {
      text: "There is no alternative added for this brand yet, you can suggest one below.",
      placeholder: "Suggest an alternative...",
    },
  },
  tr: {
    siteDescription: `Verebileceğimiz en etkili tepkilerden biri boykot. 
        Hangi markaların İsrail'in Filistin işgalini destekleyip desteklemediğini görmek için ürün/marka adını girip + işaretine tıklayınız. Aradığınız marka boykot ise, varsa yerel alternatifleri görebilirsiniz.`,
    noResult: "Herhangi bir sonuç bulunamadı, arama çubuğuna girdiğiniz markayı incelememizi istiyorsanız lütfen ",
    clickHere: "tıklayınız",
    searchBrand: (brandsNumber) => `${brandsNumber} marka arasında arayınız...`,
    isSupport: "İsraili destekliyor mu?",
    yes: "Evet",
    no: "Hayır",
    parentCompany: "Ana firma",
    how: "Nasıl?",
    moreInfo: "detaylı bilgi",
    alternatives: "Alternatifler",
    brands: "Markalar:",
    users: "kullanıcı",
    visits: "ziyaret",
    thanksForSuggestion: "Talebiniz alındı, öneriniz için teşekkürler!",
    footer: {
      info: `boycottoptions.com web sitesinde yayınlanan bilgilerin doğruluğunu sağlamak için her türlü çaba gösterilse de, bu bilgilerin mutlak doğruluğunu garanti edemeyiz. 
            Doğabilecek maddi veya manevi hata ve zararlardan boycottoptions.com sorumlu değildir. "İsrail'i Destekliyor" kategorisinde sınıflandırılan markalar aşağıdaki kriterlere göre seçiliyor:`,
      criterias: [
        "İşgalci İsrail hükümetine doğrudan destekleyen",
        "İsrail ekonomisine önemli katkı sağlayan",
      ],
      conclusion: "Bu web sitesini kullanarak yukarıdaki şartları kabul etmiş olursunuz."
    },
    contact: {
      form: "İletişim Formu",
      email: "E-posta",
      feedback: "Geribildirim",
    },
    languages: {
      en: "EN",
      tr: "TR",
      ar: "العربية"
    },
    showMore: "Daha fazla göster",
    productsListIsNotCompleted: "Not: Verilerimiz henüz tamamlanmadı, eksik kalan marka ve ürünleri sürekli olarak ekliyoruz.",
    neutral: "Tarafsız",
    noAlternative: {
      text: "Bu marka için henüz bir alternatif eklenmemiş, aşağıdaki alandan bir marka önerebilirsiniz!",
      placeholder: "Bir alternatif öner...",
    },
  },
  ar: {
    siteDescription: `المقاطعة هي إحدى أكثر الاستجابات فعالية يمكننا فعلها. لمعرفة العلامات التجارية التي تدعم أو لا تدعم الاحتلال الإسرائيلي لفلسطين، أدخل اسم المنتج/العلامة التجارية وانقر على علامة +.
         إذا تمت مقاطعة العلامة التجارية التي تبحث عنها، يمكنك رؤية البدائل المحلية إذا كانت متوفرة.`,
    noResult: "لم يتم العثور على نتائج. إذا كنت تريد منا مراجعة العلامة التجارية التي أدخلتها في شريط البحث، يرجى",
    clickHere: "الضغط هنا",
    searchBrand: (brandsNumber) => `البحث بين ${brandsNumber} علامة تجارية (بالانجليزية)...`,
    isSupport: "هل تدعم إسرائيل؟",
    yes: "نعم",
    no: "لا",
    parentCompany: "الشركة الأم",
    how: "كيف؟",
    moreInfo: "معلومات أكثر",
    alternatives: "البدائل",
    brands: "العلامات التجارية:",
    users: "مستخدم",
    visits: "زيارة",
    thanksForSuggestion: "لقد تم استلام طلبك، شكرًا لك على اقتراحك!",
    footer: {
      info: `على الرغم من بذل كل الجهود لضمان دقة المعلومات المنشورة على موقع boycottoptions.com، إلا أننا لا نستطيع ضمان دقتها المطلقة. 
            boycottoptions.com ليس مسؤولاً عن أي أخطاء أو أضرار، سواء كانت مادية أو معنوية قد تنشأ. يتم اختيار العلامات التجارية المصنفة "تدعم إسرائيل" بناء على المعايير التالية:`,
      criterias: [
        "يدعم حكومة الاحتلال الإسرائيلي بشكل مباشر",
        "تساهم بشكل كبير في الاقتصاد الإسرائيلي",
      ],
      conclusion: "باستخدامك هذا الموقع، فإنك توافق على الشروط المذكورة أعلاه."
    },
    contact: {
      form: "نموذج التواصل",
      email: "البريد الالكتروني",
      feedback: "التقييم",
    },
    languages: {
      en: "EN",
      tr: "TR",
      ar: "العربية"
    },
    showMore: "أظهر المزيد",
    productsListIsNotCompleted: "ملاحظة: بياناتنا لم تكتمل بعد، ونحن نقوم بإضافة المنتجات الناقصة بشكل مستمر.",
    neutral: "غير متحيزة",
    noAlternative: {
      text: "لم يتم إضافة بديل لهذا المنتج بعد, يمكنكم اقتراح بديل في ما بلي.",
      placeholder: "اقترح بديل...",
    },
  },
};

export default t;