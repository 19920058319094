import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import t from "../i18t";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faFileSignature, faBars, faClose } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [language, setLanguage] = useState("tr");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigationMenuRef = useRef();

  const handleLanguageChange = (_language) => {
    switch (_language) {
      case "tr":
        document.documentElement.lang = "tr";
        document.documentElement.dir = "ltr";
        setLanguage(_language);
        break;
      case "en":
        document.documentElement.lang = "en";
        document.documentElement.dir = "ltr";
        setLanguage(_language);
        break;
      case "ar":
        document.documentElement.lang = "ar";
        document.documentElement.dir = "rtl";
        setLanguage(_language);
        break;
      default:
        break;
    }
  };

  const handleClickOutsideMenu = (e) => {
    if (navigationMenuRef.current && !navigationMenuRef.current.contains(e.target)) {
      setIsCollapsed(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideMenu);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, []);

  return (
    <div className="header" dir="ltr">
      <nav>
        <div className="navbar">
          <button className='bx bx-menu' onClick={() => setIsCollapsed(true)}><FontAwesomeIcon icon={faBars} /></button>
          {/* <div className="logo"><img src="images/logo.png" alt="logo" /></div> */}
          <div className={`nav-links ${isCollapsed && 'collapsed'}`} ref={navigationMenuRef}>
            <div className="sidebar-logo">
            <img src="images/logo.png" alt="logo" />
              <button className='bx bx-x' onClick={() => setIsCollapsed(false)}><FontAwesomeIcon icon={faClose} /></button>
            </div>
            <ul className="links">
              <li><a className="link" href="https://forms.gle/h6kEedGNMBDvkWqX7" target="_blank" rel="noopener noreferrer">{t[language].contact.form} <FontAwesomeIcon icon={faFileSignature} /></a></li>
              <li><a className="link" href="https://forms.gle/RpBkJJ85AoDTAa556" target="_blank" rel="noopener noreferrer">{t[language].contact.feedback} <FontAwesomeIcon icon={faComment} /></a></li>
              <li><a className="link" href="mailto:boycottoptions@gmail.com" target="_blank" rel="noopener noreferrer">{t[language].contact.email} <FontAwesomeIcon icon={faEnvelope} /></a></li>
            </ul>
          </div>
            <div className="languages">
              <Link to="/tr" className="link" onClick={() => handleLanguageChange("tr")}>
                {t[language].languages.tr}
              </Link>
              |
              <Link to="/en" className="link" onClick={() => handleLanguageChange("en")}>
                {t[language].languages.en}
              </Link>
              |
              <Link to="/ar" className="link" onClick={() => handleLanguageChange("ar")}>
                {t[language].languages.ar}
              </Link>
            </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
