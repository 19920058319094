import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./views/Home";
import Header from "./components/Header";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home language="tr"/>}>
          <Route path="tr" element={<Home language="tr"/>} />
        </Route>
        <Route path="/en" element={<Home language="en"/>} />
        <Route path="/ar" element={<Home language="ar"/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
